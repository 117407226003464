var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SetupView emobg-background-color-white px-3 py-4" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          _c("div", { staticClass: "row w-100" }, [
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-3" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                  },
                  [_vm._v(" Created by ")]
                ),
                _c(
                  "PermissionLink",
                  {
                    attrs: {
                      "link-permissions": [_vm.CRM_PERMISSIONS.viewCrmUsers],
                      to: {
                        name: _vm.crm.users.detail.index,
                        params: {
                          userUuid: _vm.bookingSet.creatorUuid,
                        },
                      },
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.bookingSet.creatorName ||
                            _vm.FALLBACK_MESSAGE.dash
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-3" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                  },
                  [_vm._v(" Status ")]
                ),
                _c("ui-badge", { attrs: { color: _vm.statusBadgeColor } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.sentenceCase(_vm.bookingSet.status) ||
                          _vm.FALLBACK_MESSAGE.dash
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "ui-button",
            {
              attrs: {
                face: _vm.FACES.text,
                color: _vm.COLORS.danger,
                square: "",
              },
              on: {
                clickbutton: function ($event) {
                  _vm.isDeleteModalOpen = true
                },
              },
            },
            [
              _c("ui-icon", {
                attrs: { icon: _vm.ICONS.trash, color: _vm.COLORS.danger },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", {
        staticClass: "emobg-border-top-1 emobg-border-color-ground-light my-4",
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between col-12 pb-4" },
          [
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                },
                [_vm._v(" Name* ")]
              ),
              _c("span", { staticClass: "emobg-font-small" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.bookingSet.name || _vm.FALLBACK_MESSAGE.dash) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              [
                _c("EditButton", {
                  on: {
                    click: function ($event) {
                      _vm.setTargetRoute(
                        _vm.get(
                          _vm.carsharing,
                          "bookingSets.generalInformation"
                        )
                      )
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-md-6 col-lg-3 pb-4" },
          [
            _c(
              "div",
              {
                staticClass:
                  "emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
              },
              [_vm._v(" Booking type* ")]
            ),
            _c("BookingTypeBadge", { attrs: { type: _vm.bookingSet.type } }),
          ],
          1
        ),
        _vm.bookingSet.type !== _vm.BOOKING_TYPES.unavailability
          ? _c(
              "div",
              { staticClass: "col-md-6 col-lg-3 pb-4" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                  },
                  [_vm._v(" User* ")]
                ),
                _c(
                  "PermissionLink",
                  {
                    attrs: {
                      "link-permissions": [_vm.CRM_PERMISSIONS.viewCrmUsers],
                      to: {
                        name: _vm.crm.users.detail.bookings,
                        params: {
                          userUuid: _vm.bookingSet.userUuid,
                        },
                      },
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.bookingSet.userName || _vm.FALLBACK_MESSAGE.dash
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.bookingSet.type !== _vm.BOOKING_TYPES.unavailability
          ? _c("div", { staticClass: "col-md-6 col-lg-3 pb-4" }, [
              _c(
                "span",
                {
                  staticClass:
                    "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                },
                [_vm._v(" Profile* ")]
              ),
              _c("span", { staticClass: "emobg-font-small text-capitalize" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.bookingSet.userProfileAlias ||
                        _vm.FALLBACK_MESSAGE.dash
                    ) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
        _vm.isCarsharing
          ? _c("div", { staticClass: "col-md-6 col-lg-3 pb-4" }, [
              _c(
                "span",
                {
                  staticClass:
                    "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                },
                [_vm._v(" Insurance type* ")]
              ),
              _c("span", { staticClass: "emobg-font-small" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.bookingSet.insuranceName || _vm.FALLBACK_MESSAGE.dash
                    ) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
        _vm.bookingSet.type === _vm.BOOKING_TYPES.intervention
          ? _c("div", { staticClass: "col-md-6 col-lg-3 pb-4" }, [
              _c(
                "span",
                {
                  staticClass:
                    "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                },
                [_vm._v(" Intervention name* ")]
              ),
              _c("span", { staticClass: "emobg-font-small" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.bookingSet.interventionName ||
                        _vm.FALLBACK_MESSAGE.dash
                    ) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
        _vm.get(_vm.bookingSet, "companyCostAllocations.length")
          ? _c(
              "div",
              { staticClass: "col-md-6 col-lg-3 pb-4" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                  },
                  [_vm._v(" Cost allocation ")]
                ),
                _vm._l(
                  _vm.bookingSet.companyCostAllocations,
                  function (costAllocation) {
                    return _c("div", { key: costAllocation.childUuid }, [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "emobg-font-small pb-1 emobg-font-weight-bold emobg-color-ink",
                          },
                          [_vm._v(" " + _vm._s(costAllocation.name) + ": ")]
                        ),
                        _c("span", { staticClass: "emobg-font-small" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                costAllocation.codeValue ||
                                  costAllocation.customValue
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ])
                  }
                ),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "col-lg-6 pb-4" }, [
          _c(
            "span",
            {
              staticClass:
                "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
            },
            [_vm._v(" Description ")]
          ),
          _c("span", { staticClass: "emobg-font-small" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.bookingSet.description || _vm.FALLBACK_MESSAGE.dash
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _vm.isStep3
        ? _c("div", [
            _c("hr", {
              staticClass:
                "emobg-border-top-1 emobg-border-color-ground-light my-4",
            }),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between col-12 pb-4" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                        },
                        [_vm._v(" Vehicles ")]
                      ),
                      _vm._l(_vm.bookingSet.vehicles, function (vehicle) {
                        return _c(
                          "div",
                          {
                            key: vehicle.vehicleUuid,
                            staticClass: "emobg-font-small pb-1",
                          },
                          [
                            _c(
                              "RouterLink",
                              {
                                staticClass:
                                  "emobg-link color-main emobg-font-small",
                                attrs: {
                                  to: `/fleet/vehicles/${vehicle.vehicleUuid}/details`,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(" " + _vm._s(vehicle.licensePlate) + " ")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(vehicle.brand) +
                                " " +
                                _vm._s(vehicle.model) +
                                " "
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    [
                      _c("EditButton", {
                        on: {
                          click: function ($event) {
                            _vm.setTargetRoute(
                              _vm.get(
                                _vm.carsharing,
                                "bookingSets.selectVehicles"
                              )
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.bookingSet.uuid && _vm.isDeleteModalOpen
        ? _c("DeleteBookingSetModal", {
            attrs: {
              "booking-set-uuid": _vm.bookingSet.uuid,
              status: _vm.bookingSet.status,
            },
            on: {
              closeModal: () => (_vm.isDeleteModalOpen = false),
              "modal-closed": () => (_vm.isDeleteModalOpen = false),
            },
          })
        : _vm._e(),
      _vm.bookingSet.uuid && _vm.showUnsavedChangesModal
        ? _c("UnsavedChangesModal", {
            attrs: {
              status: _vm.status,
              loading: _vm.loading,
              close: () => (_vm.showUnsavedChangesModal = false),
              discard: _vm.redirectToTarget,
              save: _vm.request,
              title: "Save changes before editing?",
            },
            on: {
              closeModal: () => (_vm.showUnsavedChangesModal = false),
              "modal-closed": () => (_vm.showUnsavedChangesModal = false),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }