var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SelectVehiclesView" },
    [
      _c("h2", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isEditing ? "Edit booking set" : "Create booking set") +
            " "
        ),
      ]),
      _c("SummaryComponent", {
        attrs: {
          "has-unsaved-changes": _vm.get(
            _vm.$refs,
            "selectVehicles.hasUnsavedChanges",
            false
          ),
          request: _vm.get(_vm.$refs, "selectVehicles.request", () => {}),
          status: _vm.bookingSetVehiclesStatus,
        },
      }),
      _c("SelectVehicles", {
        ref: "selectVehicles",
        attrs: { vehicles: _vm.vehicles, "is-editing": _vm.isEditing },
        on: { "remove-vehicle": _vm.removeVehicle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }