var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingSetList__LocationsTemplate mt-n2",
      attrs: { "data-test-id": "booking_sets-locations_template" },
    },
    [
      _c(
        "div",
        { staticClass: "emobg-font-weight-bold emobg-color-ink mb-1 mb-sm-2" },
        [_vm._v(" Occurrences " + _vm._s(_vm.occurrencesLength) + " ")]
      ),
      _vm.occurrences.length
        ? _vm._l(_vm.occurrences, function (occurrence) {
            return _c("div", { key: occurrence.uuid, staticClass: "pb-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.reformatDateTime(
                      occurrence.start,
                      _vm.DATE_FORMAT.defaultExtended,
                      _vm.operatorTimezone
                    )
                  ) +
                  " to " +
                  _vm._s(
                    _vm.reformatDateTime(
                      occurrence.end,
                      _vm.DATE_FORMAT.defaultExtended,
                      _vm.operatorTimezone
                    )
                  ) +
                  " "
              ),
            ])
          })
        : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }