<script>
export default {
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    locationsLength() {
      return this.locations.length ? `(${this.locations.length})` : '';
    },
  },
};
</script>
<template>
  <div
    class="BookingSetList__LocationsTemplate mt-n2"
    data-test-id="booking_sets-locations_template"
  >
    <div class="emobg-font-weight-bold emobg-color-ink mb-1 mb-sm-2">
      Locations {{ locationsLength }}
    </div>
    <template v-if="locations.length">
      <div
        v-for="location in locations"
        :key="location && location.uuid"
        :title="location && location.name"
        class="emobg-font-small pb-1 text-ellipsis"
        style="max-width: 200px;"
        :data-test-id="`location-${location.uuid}`"
      >
        {{ location.name }}
      </div>
    </template>
    <div v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
