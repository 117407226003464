var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-tooltip",
    {
      key: _vm.bookingSetName,
      attrs: {
        tooltip: _vm.bookingSetName,
        "data-test-id": "booking_set-tooltip",
      },
    },
    [
      _c(
        "RouterLink",
        {
          staticClass: "emobg-link-primary emobg-body-2",
          attrs: {
            to: {
              name: _vm.routeName,
              params: {
                bookingSetUuid: _vm.result.uuid,
              },
            },
            "data-test-id": "link",
          },
        },
        [_vm._v(" " + _vm._s(_vm.bookingSetName) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }