<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import SummaryComponent from '../SummaryComponent/SummaryComponent';
import OccurrencesComponent from './OccurrencesComponent';
import { BOOKING_SET_STATUS } from '../../const/bookingSets.const';

export default {
  components: {
    OccurrencesComponent,
    SummaryComponent,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
      bookingSetVehiclesStatus: state => state.bookingSetVehicle.STATUS,
    }),
    isEditing() {
      return get(this, 'bookingSet.status') === BOOKING_SET_STATUS.created;
    },
  },
  async created() {
    await this.getBookingSet({ bookingSetUuid: get(this, '$route.params.bookingSetUuid') });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'getBookingSet',
    ]),
    get,
  },
};
</script>
<template>
  <div class="OccurrencesView">
    <h2 class="mb-2">
      {{ isEditing ? 'Edit booking set' : 'Create booking set' }}
    </h2>
    <SummaryComponent
      :open="false"
      :has-unsaved-changes="get($refs, 'occurrencesComponent.hasUnsavedChanges', false)"
      :request="get($refs, 'occurrencesComponent.saveCells', () => {})"
      :status="bookingSetVehiclesStatus"
      :loading="get($refs, 'occurrencesComponent.loading', false)"
    />
    <OccurrencesComponent
      v-if="bookingSet.uuid"
      ref="occurrencesComponent"
    />
  </div>
</template>
