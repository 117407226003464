var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingSetList__VehiclesTemplate mt-n2",
      attrs: { "data-test-id": "booking_sets-vehicles_template" },
    },
    [
      _c(
        "div",
        { staticClass: "emobg-font-weight-bold emobg-color-ink mb-1 mb-sm-2" },
        [_vm._v(" Vehicles " + _vm._s(_vm.vehiclesLength) + " ")]
      ),
      _vm.vehicles.length
        ? _vm._l(_vm.vehicles, function (vehicle) {
            return _c(
              "div",
              {
                key: vehicle.vehicle_uuid,
                staticClass: "emobg-font-small pb-1",
              },
              [
                _c(
                  "RouterLink",
                  {
                    staticClass: "emobg-link-primary emobg-body-2",
                    attrs: {
                      to: {
                        path: `/fleet/vehicles/${vehicle.vehicle_uuid}/details`,
                      },
                      target: "_blank",
                      "data-test-id": `${vehicle.vehicle_uuid}-link`,
                    },
                  },
                  [_vm._v(" " + _vm._s(vehicle.license_plate) + " ")]
                ),
                _vm._v(" " + _vm._s(vehicle.model) + " "),
              ],
              1
            )
          })
        : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }