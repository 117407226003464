<script>
import { TIME_ZONE } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import { bookingSetsListFacets, contentCells } from './config';

export default {
  components: {
    MuiAlgoliaList,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.BOOKINGS_INDEX = ALGOLIA_INDEXES.csBookingSets;
    const timezone = this.operatorTimezone || TIME_ZONE.default;
    this.contentCells = contentCells(timezone);
    this.bookingSetsListFacets = bookingSetsListFacets(timezone);
    this.exportColumns = this.contentCells;
  },
};
</script>
<template>
  <div
    class="BookingSetsView"
    data-test-id="booking_sets-view"
  >
    <MuiAlgoliaList
      :export-columns="exportColumns"
      :facets="bookingSetsListFacets"
      :filters="getOperatorFilter({ attribute: 'cs_operator_id' })"
      :index="BOOKINGS_INDEX"
      :table-config="contentCells"
      is-export-enabled
      no-cache
      data-test-id="list"
    />
  </div>
</template>
