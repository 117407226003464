var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.bookingSet
        ? _c(
            "ui-card",
            {
              staticClass: "OccurrencesComponent d-flex flex-column flex-fill",
            },
            [
              _c("div", { staticClass: "d-flex flex-wrap" }, [
                _c("div", { staticClass: "col-12 p-3" }, [
                  _c(
                    "h3",
                    { staticClass: "emobg-font-weight-bold" },
                    [
                      _vm._v(
                        " Create up to " +
                          _vm._s(_vm.occurrencesLimit) +
                          " occurrences "
                      ),
                      _c(
                        "ui-tooltip",
                        {
                          staticClass: "d-inline",
                          attrs: {
                            tooltip:
                              "Select when bookings should happen. You can unselect the occurrences you wish to skip",
                          },
                        },
                        [
                          _c("ui-icon", {
                            staticClass:
                              "emobg-color-ink-light emobg-color-ink-hover",
                            attrs: {
                              icon: _vm.ICONS.infoFull,
                              size: _vm.ICONS_SIZES.small,
                              "data-test-id": "icon",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "emobg-color-ink-light my-3" }, [
                    _vm._v(" Step 3 of 3 "),
                  ]),
                  _c("section", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "emobg-background-color-ground-lightest emobg-font-weight-bold p-3",
                      },
                      [
                        _vm._v(
                          " Occurrences created (" +
                            _vm._s(_vm.size(_vm.bookingSet.occurrences)) +
                            ") "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "my-3" }, [
                      _vm.unselectedCells.length
                        ? _c(
                            "p",
                            { staticClass: "row my-md-2" },
                            [
                              _c(
                                "ui-alert",
                                {
                                  staticClass: "col-lg-6",
                                  attrs: { color: _vm.COLORS.primary },
                                },
                                [
                                  _vm._v(
                                    " Skipped bookings (" +
                                      _vm._s(_vm.unselectedCells.length) +
                                      ") "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.successCells.length
                        ? _c(
                            "p",
                            { staticClass: "row my-md-2" },
                            [
                              _c("ui-alert", { staticClass: "col-lg-6" }, [
                                _vm._v(
                                  " Created bookings (" +
                                    _vm._s(_vm.successCells.length) +
                                    ") "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.failedCells.length
                        ? _c(
                            "p",
                            { staticClass: "row my-md-2" },
                            [
                              _c(
                                "ui-alert",
                                {
                                  staticClass: "col-lg-6",
                                  attrs: { color: _vm.COLORS.danger },
                                },
                                [
                                  _vm._v(
                                    " Failed bookings (" +
                                      _vm._s(_vm.failedCells.length) +
                                      ") "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "p",
                      { staticClass: "text-right my-md-2" },
                      [
                        _c(
                          "ui-button",
                          {
                            attrs: {
                              face: _vm.FACES.outline,
                              disabled:
                                _vm.size(_vm.bookingSet.occurrences) ===
                                _vm.occurrencesLimit,
                            },
                            on: {
                              clickbutton: function ($event) {
                                _vm.isOccurrenceFormOpen = true
                              },
                            },
                          },
                          [_vm._v(" Create occurrence ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "OpsGrid d-flex emobg-border-1 emobg-border-color-ground-light",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "with-shadow flex-shrink-0 emobg-border-right-1 emobg-border-color-ground-light",
                          class: {
                            "flex-grow-1": !_vm.get(
                              _vm.bookingSet,
                              "occurrences.length"
                            ),
                          },
                          style: {
                            width: _vm.vehiclesWidth + "px",
                          },
                        },
                        [
                          _c(
                            "header",
                            {
                              staticClass:
                                "Header__row emobg-background-color-ground-lighter emobg-font-line-height-medium d-flex align-items-center emobg-border-bottom-4 emobg-border-color-ground-light",
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "px-2 py-3 px-md-3 py-md-4 Header__row",
                                    {
                                      "col-4 col-md-3 col-lg-2 emobg-border-right-1 emobg-border-color-ground-light":
                                        !_vm.get(
                                          _vm.bookingSet,
                                          "occurrences.length"
                                        ),
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "emobg-font-weight-bold" },
                                    [
                                      _vm._v(
                                        " Vehicles (" +
                                          _vm._s(
                                            _vm.size(_vm.bookingSet.vehicles)
                                          ) +
                                          ") "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "section",
                            { staticClass: "OpsGrid__content" },
                            _vm._l(
                              _vm.bookingSet.vehicles,
                              function (vehicle, vehicleIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: `OpsGrid-vehicle-${vehicleIndex}`,
                                    class: [
                                      "SelectVehicles__row",
                                      "d-flex align-items-start justify-content-center flex-column",
                                      "emobg-border-color-ground-light",
                                      {
                                        "emobg-border-bottom-1": _vm.get(
                                          _vm.bookingSet,
                                          "occurrences.length"
                                        ),
                                      },
                                      {
                                        "emobg-background-color-ground-lightest":
                                          vehicleIndex % 2,
                                      },
                                      {
                                        "emobg-border-right-1": !_vm.get(
                                          _vm.bookingSet,
                                          "occurrences.length"
                                        ),
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          "p-2 p-md-3",
                                          {
                                            "col-4 col-md-3 col-lg-2 pl-0 emobg-border-right-1 emobg-border-color-ground-light":
                                              !_vm.get(
                                                _vm.bookingSet,
                                                "occurrences.length"
                                              ),
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "LinkSwitch",
                                          {
                                            staticClass:
                                              "emobg-link-primary emobg-body-2",
                                            attrs: {
                                              "use-router": "",
                                              to: `/fleet/vehicles/${vehicle.vehicleUuid}/details`,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(vehicle.licensePlate) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(vehicle.brand) +
                                              " " +
                                              _vm._s(vehicle.model) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _vm.get(_vm.bookingSet, "occurrences.length")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "overflow-x-auto overflow-y-hidden flex-grow-1 d-flex OccurrencesList",
                            },
                            _vm._l(
                              _vm.sortedOccurrences,
                              function (occurrence, occurrenceIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: `OpsGrid-occurrence-${occurrenceIndex}`,
                                    staticClass:
                                      "OccurrencesList__column flex-shrink-0",
                                  },
                                  [
                                    _c(
                                      "header",
                                      {
                                        staticClass:
                                          "Header__row p-2 p-md-3 emobg-background-color-ground-lighter emobg-font-line-height-medium emobg-border-bottom-4 emobg-border-color-ground-light d-flex align-items-center justify-content-between",
                                        class: {
                                          "emobg-border-right-1":
                                            occurrence !==
                                            _vm.last(_vm.sortedOccurrences),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-between",
                                            style: {
                                              maxWidth:
                                                _vm.occurrencesWidth + "px",
                                            },
                                          },
                                          [
                                            _c("MuiCheckbox", {
                                              staticClass: "d-flex flex-column",
                                              attrs: {
                                                size: _vm.SIZES.small,
                                                values: _vm.map(
                                                  _vm.selectableCellsByOccurrence(
                                                    occurrence.uuid
                                                  ),
                                                  "uuid"
                                                ),
                                              },
                                              model: {
                                                value: _vm.checkedCells,
                                                callback: function ($$v) {
                                                  _vm.checkedCells = $$v
                                                },
                                                expression: "checkedCells",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column px-1",
                                              },
                                              [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "emobg-font-weight-bold pb-1",
                                                  },
                                                  [_vm._v(" Occurrence ")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "emobg-font-x-small",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.reformatDateTime(
                                                              occurrence.start,
                                                              _vm.DATE_FORMAT
                                                                .defaultExtended,
                                                              _vm.operatorTimezone
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _vm._v(" to "),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.reformatDateTime(
                                                              occurrence.end,
                                                              _vm.DATE_FORMAT
                                                                .defaultExtended,
                                                              _vm.operatorTimezone
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "emobg-font-x-small emobg-color-ink-light pb-1",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.duration(
                                                              occurrence.start,
                                                              occurrence.end
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              [
                                                !_vm.loading &&
                                                !_vm.hasBookings(
                                                  occurrence.uuid
                                                )
                                                  ? _c(
                                                      "ui-dropdown",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.GRAYSCALE
                                                              .groundLighter,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "ui-button",
                                                          {
                                                            attrs: {
                                                              slot: "trigger",
                                                              color:
                                                                _vm.GRAYSCALE
                                                                  .groundLighter,
                                                              contrast: "",
                                                              square: "",
                                                            },
                                                            slot: "trigger",
                                                          },
                                                          [
                                                            _c("ui-icon", {
                                                              attrs: {
                                                                color:
                                                                  _vm.GRAYSCALE
                                                                    .inkLight,
                                                                icon: _vm.ICONS
                                                                  .optionsHorizontalFull,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "ui-dropdown-actions",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                              "data-test-id":
                                                                "list",
                                                            },
                                                            domProps: {
                                                              actions: [
                                                                {
                                                                  label: "Edit",
                                                                  action: () =>
                                                                    _vm.openEditOccurrenceModal(
                                                                      occurrence
                                                                    ),
                                                                },
                                                                {
                                                                  label:
                                                                    "Delete",
                                                                  action: () =>
                                                                    _vm.openDeleteOccurrenceModal(
                                                                      occurrence
                                                                    ),
                                                                },
                                                              ],
                                                            },
                                                            slot: "content",
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "section",
                                      {
                                        staticClass: "OpsGrid__content",
                                        class: {
                                          "emobg-border-right-1 emobg-border-color-ground-light":
                                            occurrence !==
                                            _vm.last(_vm.sortedOccurrences),
                                        },
                                      },
                                      _vm._l(
                                        _vm.bookingSet.vehicles,
                                        function (vehicle, vehicleIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: `OpsGrid-vehicle-${vehicleIndex}`,
                                              staticClass:
                                                "SelectVehicles__row d-flex align-items-center p-2 px-md-3 py-md-4 emobg-border-bottom-1 emobg-border-color-ground-light",
                                              class: {
                                                "emobg-background-color-ground-lightest":
                                                  vehicleIndex % 2,
                                              },
                                            },
                                            [
                                              _c("ui-checkbox", {
                                                attrs: {
                                                  checked:
                                                    _vm.isVehicleIncluded(
                                                      vehicle.uuid,
                                                      occurrence.uuid
                                                    ),
                                                  disabled:
                                                    !_vm.isCellAbleToSelect(
                                                      _vm.cellByOccurrenceAndVehicle(
                                                        vehicle.uuid,
                                                        occurrence.uuid
                                                      )
                                                    ),
                                                },
                                                on: {
                                                  changevalue: () =>
                                                    _vm.handleCheckedCellsUpdate(
                                                      vehicle.uuid,
                                                      occurrence.uuid
                                                    ),
                                                },
                                              }),
                                              _c("OccurrenceCellTemplate", {
                                                attrs: {
                                                  cell: _vm.cellByOccurrenceAndVehicle(
                                                    vehicle.uuid,
                                                    occurrence.uuid
                                                  ),
                                                  type: _vm.toLower(
                                                    _vm.bookingSet.type
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-border-top-1 emobg-border-color-ground-light p-3",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                    },
                    [
                      _c(
                        "ui-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            face: _vm.FACES.outline,
                            color: _vm.GRAYSCALE.inkLight,
                            disabled: _vm.loading,
                          },
                          on: {
                            clickbutton: function ($event) {
                              _vm.hasUnsavedChanges
                                ? (_vm.showUnsavedChangesModal = true)
                                : _vm.redirect()
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                      _c(
                        "ui-button",
                        {
                          attrs: {
                            disabled: !_vm.bookingsNumber,
                            loading: _vm.loading,
                          },
                          on: { clickbutton: _vm.createBookings },
                        },
                        [
                          _vm._v(
                            " Create bookings (" +
                              _vm._s(_vm.bookingsNumber) +
                              ") "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.isOccurrenceFormOpen
        ? _c("OccurrenceForm", {
            attrs: {
              close: _vm.closeOccurrenceModal,
              occurrence: _vm.currentOccurrence,
            },
            on: {
              closeModal: _vm.closeOccurrenceModal,
              "modal-closed": _vm.closeOccurrenceModal,
            },
          })
        : _vm._e(),
      _vm.isDeleteOccurrenceFormOpen
        ? _c("DeleteOccurrenceForm", {
            attrs: {
              close: _vm.closeOccurrenceModal,
              occurrence: _vm.currentOccurrence,
            },
            on: {
              closeModal: _vm.closeOccurrenceModal,
              "modal-closed": _vm.closeOccurrenceModal,
              deleteOccurence: _vm.updateCheckedCells,
            },
          })
        : _vm._e(),
      _vm.showUnsavedChangesModal
        ? _c("UnsavedChangesModal", {
            attrs: {
              status: _vm.bookingSetCellsSaveStatus,
              loading: _vm.loading,
              title: "Save changes before closing?",
              close: () => (_vm.showUnsavedChangesModal = false),
              discard: _vm.redirect,
              save: _vm.saveCells,
            },
            on: {
              closeModal: () => (_vm.showUnsavedChangesModal = false),
              "modal-closed": () => (_vm.showUnsavedChangesModal = false),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }