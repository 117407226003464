import remove from 'lodash/remove';
import find from 'lodash/find';
import size from 'lodash/size';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import join from 'lodash/join';
import {
  FALLBACK_MESSAGE,
  isNullString,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { AlertsTemplate } from '@/templates';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export const maxVehiclesSelected = 10;

export const algoliaListConstants = {
  index: 'vehicles',
  queryParameters: {
    attributesToRetrieve: [
      'alerts',
      'api',
      'battery_level',
      'chassis_number',
      'city',
      'dedicated_cs_operator',
      'device',
      'id',
      'is_premium',
      'license_plate',
      'location',
      'mileage',
      'mileage_unit',
      'model',
      'seats',
      'status',
      'transmission',
      'uuid',
      'vehicle_brand',
      'vehicle_category',
      'vehicle_fuel_type',
      'vehicle_model',
      'vehicle_model_picture',
    ],
    restrictSearchableAttributes: [
      'alerts',
      'api',
      'city',
      'dedicated_cs_operator',
      'device.qnr',
      'id',
      'is_premium',
      'license_plate',
      'location',
      'mileage',
      'mileage_unit',
      'model.brand.uuid',
      'model.uuid',
      'seats',
      'status',
      'transmission',
      'vehicle_brand',
      'vehicle_category',
      'vehicle_fuel_type',
      'vehicle_model',
    ],
    hitsPerPage: 15,
  },
};
export const getActions = scope => [
  {
    label: 'Clear selected',
    class: 'emobg-color-danger',
    method: searchStore => {
      remove(scope.vehicles, vehicle => !scope.hasCellsBooked(vehicle.vehicleUuid));
      scope.vehicles.splice();
      searchStore.refresh();
    },
  },
];
export const getFacets = scope => [
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'dedicated_cs_operator',
      transformValue: value => (isNullString(value) ? scope.operatorName : value),
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'city',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Location',
      attributeName: 'location',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Alerts',
      attributeName: 'alerts',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Brand',
      attributeName: 'vehicle_brand',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Model',
      attributeName: 'vehicle_model',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Category',
      attributeName: 'vehicle_category',
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Type',
      attributeName: 'model.vehicle_type',
      transformValue: sentenceCase,
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Fuel type',
      attributeName: 'vehicle_fuel_type',
      transformValue: sentenceCase,
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Premium vehicle',
      attributeName: 'is_premium',
      transformValue: value => (value === '1' ? 'Yes' : 'No'),
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Transmission',
      attributeName: 'transmission',
      transformValue: sentenceCase,
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Visible',
      attributeName: 'api',
      transformValue: value => (value === '1' ? 'Yes' : 'No'),
      toggled: true,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Seats',
      attributeName: 'seats',
      toggled: true,
    },
  },
];
export const getTableConfig = scope => [
  {
    type: RECORD_TYPES.checkbox,
    displayPriority: 1,
    model: scope.vehicles,
    key: 'uuid',
    value: result => ({
      vehicleUuid: result.uuid,
      vehicleId: result.id,
      licensePlate: result.license_plate,
      model: `${result.vehicle_model}`,
      brand: `${result.vehicle_brand}`,
      locationName: result.location === 'null' ? null : result.location,
    }),
    disabled: result => scope.hasCellsBooked(result.uuid)
        || (!find(scope.vehicles, { vehicleUuid: result.uuid }) && size(scope.vehicles) >= maxVehiclesSelected),
    columnRatio: 0.01,
    minWidth: 52,
  },
  {
    attributeName: 'license_plate',
    title: 'Plate',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 90,
    type: RECORD_TYPES.link,
    url: result => `/fleet/vehicles/${result.uuid}/details`,
  },
  {
    title: 'Model',
    transformResult: result => `${result.vehicle_brand} - ${result.vehicle_model}`,
    displayPriority: 1,
    columnRatio: 2,
    minWidth: 150,
  },
  {
    attributeName: 'vehicle_category',
    title: 'Category',
    displayPriority: 2,
    columnRatio: 1,
    minWidth: 90,
  },
  {
    attributeName: 'model.vehicle_type',
    title: 'Type',
    displayPriority: 2,
    columnRatio: 1,
    minWidth: 120,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'city',
    title: 'City',
    displayPriority: 2,
    columnRatio: 1,
    minWidth: 100,
  },
  {
    attributeName: 'location',
    title: 'Location',
    columnRatio: 3,
    displayPriority: 2,
    minWidth: 170,
    transformValue: location => location.replace(/"/g, "'"),
  },
  {
    title: 'Alerts',
    attributeName: 'alerts',
    columnRatio: 1,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ alerts }) => {
      const numberOfAlerts = get(alerts, 'length');
      return {
        text: numberOfAlerts || '0',
        color: numberOfAlerts ? COLORS.warning : GRAYSCALE.ground,
      };
    },
    displayPriority: 2,
    minWidth: 120,
    isCollapseHidden: true,
  },
  {
    title: 'Status',
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ status }) => ({
      text: sentenceCase(status),
      color: toLower(status) === 'active' ? COLORS.success : COLORS.danger,
    }),
    columnRatio: 1,
    displayPriority: 2,
    minWidth: 120,
  },
  {
    attributeName: 'vehicle_model_picture',
    title: 'Picture',
    type: RECORD_TYPES.image,
    columnRatio: 1,
    minWidth: 120,
    displayPriority: 2,
  },
  {
    attributeName: 'vehicle_fuel_type',
    title: 'Fuel type',
    minWidth: 70,
    transformValue: sentenceCase,
  },
  {
    title: 'Premium vehicle',
    minWidth: 120,
    transformResult: result => (result.is_premium ? 'Yes' : 'No'),
  },
  {
    attributeName: 'device.qnr',
    title: 'Device QNR',
    type: RECORD_TYPES.link,
    minWidth: 155,
    url: vehicle => {
      const deviceID = get(vehicle, 'device.id');
      return deviceID ? `/fleet/devices/${deviceID}` : '#';
    },
  },
  {
    title: 'Mileage',
    minWidth: 85,
    transformResult: result => `${result.mileage || FALLBACK_MESSAGE.dash} ${result.mileage_unit || FALLBACK_MESSAGE.dash}`,
  },
  {
    title: 'Battery level',
    attributeName: 'battery_level',
    minWidth: 85,
    transformValue: value => value || FALLBACK_MESSAGE.dash,
  },
  {
    attributeName: 'chassis_number',
    title: 'Chassis number',
    minWidth: 170,
  },
  {
    title: 'Alert reasons',
    attributeName: 'alerts',
    type: RECORD_TYPES.component,
    component: AlertsTemplate,
    displayPriority: 0,
    minWidth: 280,
    props: result => ({
      result,
      alerts: result.alerts,
    }),
    columnRatio: 1,
    transformValue: alerts => join(alerts, ', '),
  },
  {
    attributeName: 'seats',
    title: 'Seats',
    minWidth: 40,
  },
  {
    attributeName: 'transmission',
    title: 'Transmission',
    transformValue: sentenceCase,
    minWidth: 95,
  },
  {
    title: 'Visible',
    transformResult: result => (result.api ? 'Yes' : 'No'),
    minWidth: 50,
  },
];

export const algoliaListScoped = scope => ({
  actions: getActions(scope),
  facets: getFacets(scope),
  tableConfig: getTableConfig(scope),
});

