var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingSetsView",
      attrs: { "data-test-id": "booking_sets-view" },
    },
    [
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.exportColumns,
          facets: _vm.bookingSetsListFacets,
          filters: _vm.getOperatorFilter({ attribute: "cs_operator_id" }),
          index: _vm.BOOKINGS_INDEX,
          "table-config": _vm.contentCells,
          "is-export-enabled": "",
          "no-cache": "",
          "data-test-id": "list",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }