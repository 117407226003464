var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingSetList__LocationsTemplate mt-n2",
      attrs: { "data-test-id": "booking_sets-locations_template" },
    },
    [
      _c(
        "div",
        { staticClass: "emobg-font-weight-bold emobg-color-ink mb-1 mb-sm-2" },
        [_vm._v(" Locations " + _vm._s(_vm.locationsLength) + " ")]
      ),
      _vm.locations.length
        ? _vm._l(_vm.locations, function (location) {
            return _c(
              "div",
              {
                key: location && location.uuid,
                staticClass: "emobg-font-small pb-1 text-ellipsis",
                staticStyle: { "max-width": "200px" },
                attrs: {
                  title: location && location.name,
                  "data-test-id": `location-${location.uuid}`,
                },
              },
              [_vm._v(" " + _vm._s(location.name) + " ")]
            )
          })
        : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }