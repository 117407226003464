var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OccurrenceCellTemplate d-flex flex-grow-1" },
    [
      _vm.isPending
        ? _c("div", [_vm._v(" Create booking ")])
        : _vm.cell.status === _vm.CELL_STATUS.failed
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-grow-1 justify-content-between align-items-center",
            },
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("span", { staticClass: "emobg-color-danger" }, [
                  _vm._v(" Booking failed "),
                ]),
                _c("div", {
                  staticClass: "OccurrenceCellTemplate__error-wrapper",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.getMessageText(
                        _vm.customErrorDataSnakeCase,
                        _vm.$t,
                        _vm.operatorTimezone
                      )
                    ),
                  },
                }),
              ]),
            ]
          )
        : _vm.isBooked
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-grow-1 justify-content-between align-items-center",
            },
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "pb-1" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "emobg-color-success",
                        staticStyle: { opacity: "0.5" },
                      },
                      [_vm._v(" Booking created ")]
                    ),
                    _c(
                      "PermissionLink",
                      {
                        attrs: {
                          "link-permissions": [
                            _vm.CARSHARING_PERMISSIONS.viewCarsharingBookings,
                          ],
                          to: {
                            name: _vm.carsharingRoutes.bookings.detail.index,
                            params: {
                              bookingUuid: _vm.get(_vm.cell, "bookingUuid"),
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          " #" + _vm._s(_vm.get(_vm.cell, "bookingId")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.cell.updateMetadata
                  ? _c(
                      "div",
                      [
                        _c(
                          "span",
                          { staticClass: "emobg-color-moderate-light" },
                          [_vm._v(" · Something has changed ")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "emobg-link-primary emobg-body-2",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.showUpdateInfoCard = true
                              },
                            },
                          },
                          [_vm._v(" show more ")]
                        ),
                        _vm.showUpdateInfoCard
                          ? _c("UpdatedInfoCard", {
                              attrs: {
                                close: () => (_vm.showUpdateInfoCard = false),
                                metadata: _vm.cell.updateMetadata,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                [
                  _c(
                    "ui-badge",
                    {
                      staticClass: "d-flex align-items-center mb-1",
                      attrs: { color: _vm.GRAYSCALE.ground },
                    },
                    [
                      _c("ui-icon", {
                        staticClass: "d-inline-block p-1",
                        attrs: {
                          icon: _vm.ICONS.systemStatus,
                          size: _vm.ICONS_SIZES.xSmall,
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.sentenceCase(_vm.cell.bookingDomainStatus)
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "ui-badge",
                    {
                      staticClass: "d-flex align-items-center mb-1",
                      attrs: { color: _vm.GRAYSCALE.ground },
                    },
                    [
                      _c("ui-icon", {
                        staticClass: "d-inline-block p-1",
                        attrs: {
                          icon: _vm.ICONS.vehicleStatus,
                          size: _vm.ICONS_SIZES.xSmall,
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.type === _vm.BOOKING_TYPES.unavailability
                              ? _vm.FALLBACK_MESSAGE.notAvailable
                              : _vm.sentenceCase(_vm.cell.bookingFleetStatus)
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }