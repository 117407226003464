var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass:
        "emobg-border-1 emobg-border-color-ground-light shadow-s emobg-background-color-white position-relative p-2 mt-n6 ml-n3",
    },
    [
      _c(
        "ui-button",
        {
          staticClass: "float-right",
          attrs: { face: _vm.FACES.text, square: "" },
          on: { clickbutton: _vm.close },
        },
        [
          _c("ui-icon", {
            attrs: {
              icon: _vm.ICONS.cross,
              color: _vm.GRAYSCALE.ground,
              size: _vm.ICONS_SIZES.small,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex flex-column p-3" }, [
        _vm.vehicleChanged
          ? _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", { staticClass: "emobg-font-weight-bold" }, [
                _vm._v("New vehicle: "),
              ]),
              _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.vehicleChanged.values.licensePlate) + " "
                ),
              ]),
            ])
          : _vm._e(),
        _vm.datesChanged
          ? _c("div", { staticClass: "d-flex flex-column pb-2" }, [
              _c("span", { staticClass: "emobg-font-weight-bold pb-1" }, [
                _vm._v(" New dates: "),
              ]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.reformatDateTime(
                        _vm.datesChanged.values.startDate,
                        _vm.DATE_FORMAT.defaultExtended,
                        _vm.operatorTimezone
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.reformatDateTime(
                        _vm.datesChanged.values.endDate,
                        _vm.DATE_FORMAT.defaultExtended,
                        _vm.operatorTimezone
                      )
                    ) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
        _vm.insuranceChanged
          ? _c("div", { staticClass: "d-flex flex-column pb-2" }, [
              _c("span", { staticClass: "emobg-font-weight-bold pb-1" }, [
                _vm._v(" New insurance: "),
              ]),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.insuranceChanged.values.name) + " "),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }