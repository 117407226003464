var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        attrs: { header: null, size: _vm.SIZES.small, "backdrop-dismiss": "" },
      },
      _vm.$listeners
    ),
    [
      _c("template", { slot: "body" }, [
        _vm.title
          ? _c("span", {
              staticClass:
                "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large pb-3 emobg-color-ink",
              domProps: { innerHTML: _vm._s(_vm.title) },
            })
          : _vm._e(),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-content-center justify-content-end w-100",
          },
          [
            _c(
              "ui-button",
              {
                staticClass: "mr-2",
                attrs: { color: _vm.COLORS.danger, face: _vm.FACES.outline },
                on: { clickbutton: _vm.discard },
              },
              [_vm._v(" Discard ")]
            ),
            _c(
              "ui-button",
              {
                attrs: { loading: _vm.status.LOADING || _vm.loading },
                on: { clickbutton: _vm.saveAndClose },
              },
              [_vm._v(" Save ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }