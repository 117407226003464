var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OccurrencesView" },
    [
      _c("h2", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isEditing ? "Edit booking set" : "Create booking set") +
            " "
        ),
      ]),
      _c("SummaryComponent", {
        attrs: {
          open: false,
          "has-unsaved-changes": _vm.get(
            _vm.$refs,
            "occurrencesComponent.hasUnsavedChanges",
            false
          ),
          request: _vm.get(
            _vm.$refs,
            "occurrencesComponent.saveCells",
            () => {}
          ),
          status: _vm.bookingSetVehiclesStatus,
          loading: _vm.get(_vm.$refs, "occurrencesComponent.loading", false),
        },
      }),
      _vm.bookingSet.uuid
        ? _c("OccurrencesComponent", { ref: "occurrencesComponent" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }