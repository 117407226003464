var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "GeneralInformationView",
      attrs: { "data-test-id": "general_information-view" },
    },
    [
      _c("h2", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isEditing ? "Edit booking set" : "Create booking set") +
            " "
        ),
      ]),
      _c(
        "ui-card",
        {
          staticClass: "d-flex flex-column flex-fill",
          attrs: { "data-test-id": "card" },
        },
        [
          _c("div", { staticClass: "d-flex flex-wrap" }, [
            _c("div", { staticClass: "col-12 p-3" }, [
              _c("h3", { staticClass: "emobg-font-weight-bold" }, [
                _vm._v(" Add details "),
              ]),
              _c("p", { staticClass: "emobg-color-ink-light my-3" }, [
                _vm._v(" Step 1 of 3 "),
              ]),
              _c("hr", {
                staticClass:
                  "emobg-border-top-1 emobg-border-color-ground-light my-3",
              }),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 mb-3" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n                isRequired: true,\n              }",
                        },
                      ],
                      attrs: {
                        label: "Name*",
                        placeholder: "Name this booking set",
                        name: "name",
                        "data-test-id": "booking_set_name-input",
                      },
                      model: {
                        value: _vm.inputs.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "name", $$v)
                        },
                        expression: "inputs.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 d-flex flex-column align-items-start mb-3",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-block emobg-font-small emobg-font-weight-semibold emobg-color-ink pl-1",
                      },
                      [_vm._v("Booking type*")]
                    ),
                    _vm._l(_vm.values(_vm.BOOKING_TYPES), function (type) {
                      return _c("ui-radio", {
                        key: type,
                        attrs: {
                          value: _vm.inputs.type,
                          caption: _vm.sentenceCase(type),
                          option: type,
                          disabled: _vm.isBookingCreated,
                          name: "type",
                          "data-test-id": `booking_${_vm.kebabCase(
                            type
                          )}-update-button`,
                        },
                        on: {
                          changevalue: () => {
                            _vm.inputs.type = type
                            _vm.updateUserInfo()
                          },
                        },
                      })
                    }),
                  ],
                  2
                ),
                _vm.inputs.type !== _vm.BOOKING_TYPES.unavailability
                  ? _c(
                      "div",
                      { staticClass: "col-12 mb-3" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-block emobg-font-small emobg-font-weight-semibold emobg-color-ink pl-1 mb-1",
                          },
                          [_vm._v("User*")]
                        ),
                        _vm.isInitialized
                          ? _c("MuiAlgoliaSelect", {
                              staticClass: "w-100 mb-0 pb-2",
                              attrs: {
                                disabled: _vm.isBookingCreated,
                                filters: _vm.getOperatorFilter({
                                  index: _vm.ALGOLIA_INDEXES.users,
                                }),
                                index: _vm.ALGOLIA_INDEXES.users,
                                "results-per-page": 50,
                                title: (result) =>
                                  `${result.first_name} ${result.last_name} - ${
                                    result.email ? result.email : "[No email]"
                                  }`,
                                model: _vm.inputs.userUuid,
                                name: "user",
                                "path-value": "uuid",
                                placeholder: "Select",
                                "data-test-id": "user-select",
                              },
                              on: { change: _vm.onUserChange },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.inputs.type !== _vm.BOOKING_TYPES.unavailability
                  ? _c(
                      "div",
                      { staticClass: "col-12 mb-3" },
                      [
                        _vm.isUserProfilesLoading
                          ? _c("ui-skeleton", { staticClass: "mb-2" })
                          : _c("MuiSelect", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100 mb-2",
                              attrs: {
                                "model-value": _vm.inputs.userProfileUuid,
                                disabled:
                                  _vm.isEmpty(_vm.inputs.userUuid) ||
                                  _vm.isBookingCreated,
                                options: _vm.map(
                                  _vm.userProfiles,
                                  (profile) => ({
                                    value: profile.uuid,
                                    label: _vm.sentenceCase(profile.type),
                                  })
                                ),
                                placeholder: "Select",
                                label: "Profile*",
                                name: "profile",
                                "data-test-id": "profile-select",
                              },
                              on: { change: _vm.onChangeProfile },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCarsharing,
                        expression: "isCarsharing",
                      },
                    ],
                    staticClass: "col-12 mb-3",
                  },
                  [
                    _vm.isUserProfilesLoading
                      ? _c("ui-skeleton", { staticClass: "mb-2" })
                      : _c("MuiSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n                isRequired: true,\n              }",
                            },
                          ],
                          staticClass: "w-100 mb-2",
                          attrs: {
                            disabled:
                              _vm.isEmpty(_vm.inputs.userProfileUuid) ||
                              _vm.isBookingCreated,
                            options: _vm.map(
                              _vm.insurances,
                              ({ uuid, name }) => ({ value: uuid, label: name })
                            ),
                            placeholder: "Select",
                            label: "Insurance*",
                            name: "insurance",
                            "data-test-id": "insurance-select",
                          },
                          model: {
                            value: _vm.inputs.insuranceUuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "insuranceUuid", $$v)
                            },
                            expression: "inputs.insuranceUuid",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.inputs.type === _vm.BOOKING_TYPES.intervention,
                        expression:
                          "inputs.type === BOOKING_TYPES.intervention",
                      },
                    ],
                    staticClass: "col-12 mb-3",
                  },
                  [
                    _vm.isInitialized
                      ? _c("MuiAlgoliaSelect", {
                          staticClass: "w-100 m-0 pb-2",
                          attrs: {
                            disabled:
                              !_vm.inputs.userProfileUuid ||
                              _vm.isBookingCreated,
                            index: _vm.ALGOLIA_INDEXES.carsharingTags,
                            "results-per-page": 50,
                            title: _vm.interventionTitle,
                            filters: "group:interventions",
                            label: "Intervention type*",
                            name: "intervention",
                            "path-value": "internal_name",
                            placeholder: "Select",
                            "data-test-id": "intervention-select",
                          },
                          model: {
                            value: _vm.inputs.interventionType,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "interventionType", $$v)
                            },
                            expression: "inputs.interventionType",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.currentProfile.costAllocations &&
                _vm.currentProfile.costAllocations.length
                  ? _c(
                      "div",
                      { staticClass: "col-12 mb-3" },
                      [
                        _c("CostAllocationsComponent", {
                          attrs: {
                            disabled: _vm.isBookingCreated,
                            "company-cost-allocations":
                              _vm.currentProfile.costAllocations,
                            "data-test-id": "cost_allocations",
                          },
                          on: {
                            isValid: (value) =>
                              (_vm.isCostAllocationsValid = value),
                          },
                          model: {
                            value: _vm.inputs.companyCostAllocations,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inputs,
                                "companyCostAllocations",
                                $$v
                              )
                            },
                            expression: "inputs.companyCostAllocations",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-12 mb-3" },
                  [
                    _c("ui-text-area", {
                      attrs: {
                        value: _vm.inputs.description,
                        label: `Description${
                          _vm.isInterventionOrUnavailability ? "*" : ""
                        }`,
                        name: "description",
                        rows: "8",
                        "data-test-id": "description-textarea",
                      },
                      on: {
                        changevalue: (event) =>
                          (_vm.inputs.description = event.detail),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "emobg-border-top-1 emobg-border-color-ground-light p-3",
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                },
                [
                  _c(
                    "ui-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: _vm.GRAYSCALE.ink,
                        face: _vm.FACES.text,
                        "data-test-id": "redirect-button",
                      },
                      on: {
                        clickbutton: function ($event) {
                          return _vm.redirect(true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("Common.Actions.cancel")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        disabled: !_vm.areRequiredFieldsFilled,
                        loading: _vm.bookingSetStatus.LOADING,
                        "data-test-id": "request-button",
                      },
                      on: { clickbutton: _vm.doRequest },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.isEditing ? "Save" : "Next") + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }