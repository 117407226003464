<script>
import { PageView } from '@/components';

export default {
  name: 'BookingSetsView',
  components: { PageView },
};
</script>

<template>
  <PageView class="BookingSetsView">
    <RouterView />
  </PageView>
</template>
