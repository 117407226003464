<script>
import {
  DATE_FORMAT,
  reformatDateTime,
} from '@emobg/web-utils';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  props: {
    occurrences: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    occurrencesLength() {
      return this.occurrences.length ? `(${this.occurrences.length})` : '';
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.reformatDateTime = reformatDateTime;
  },
};
</script>
<template>
  <div
    class="BookingSetList__LocationsTemplate mt-n2"
    data-test-id="booking_sets-locations_template"
  >
    <div class="emobg-font-weight-bold emobg-color-ink mb-1 mb-sm-2">
      Occurrences {{ occurrencesLength }}
    </div>
    <template v-if="occurrences.length">
      <div
        v-for="occurrence in occurrences"
        :key="occurrence.uuid"
        class="pb-2"
      >
        {{ reformatDateTime(occurrence.start, DATE_FORMAT.defaultExtended, operatorTimezone) }}
        to
        {{ reformatDateTime(occurrence.end, DATE_FORMAT.defaultExtended, operatorTimezone) }}
      </div>
    </template>
    <div v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
