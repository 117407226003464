import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import BookingTypeBadge from '@/domains/Carsharing/components/BookingTypeBadge/BookingTypeBadge';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import {
  BookingSetLinkTemplate,
  CitiesTemplate,
  LocationsTemplate,
  OccurrencesTemplate,
  VehiclesTemplate,
} from '../components';

const statusColors = {
  draft: COLORS.moderate,
  created: COLORS.warning,
  success: COLORS.success,
  completed: GRAYSCALE.ground,
};

export function contentCells(timezone) {
  return [
    {
      attributeName: 'name',
      title: 'Name',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BookingSetLinkTemplate,
      props: result => ({ result }),
    },
    {
      attributeName: 'start_date_ts',
      title: 'Start date',
      displayPriority: 1,
      transformValue: value => (value ? formatUtc(value, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash),
    },
    {
      attributeName: 'end_date_ts',
      title: 'End date',
      displayPriority: 1,
      transformValue: value => (value ? formatUtc(value, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash),
    },
    {
      attributeName: 'type',
      title: 'Booking type',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BookingTypeBadge,
      props: bookingSet => ({
        type: bookingSet.type,
      }),
    },
    {
      title: 'User',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.bookings,
          params: {
            userUuid: result.user_uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: result.user_name,
      }),
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: statusColors[status],
      }),
    },
    {
      attributeName: 'cities',
      title: 'Cities',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: CitiesTemplate,
      props: result => ({
        cities: result.cities,
      }),
    },
    {
      attributeName: 'locations',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: LocationsTemplate,
      props: result => ({
        locations: result.locations,
      }),
    },
    {
      title: 'Created by',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.index,
          params: {
            userUuid: result.creator_uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: result.creator_name,
      }),
    },
    {
      attributeName: 'vehicles',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: VehiclesTemplate,
      props: result => ({
        vehicles: result.vehicles,
      }),
    },
    {
      attributeName: 'occurrences',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: OccurrencesTemplate,
      props: result => ({
        occurrences: result.occurrences,
      }),
    },
    {
      attributeName: 'description',
      displayPriority: 0,
      title: 'Description',
      type: RECORD_TYPES.template,
      template: ({ description }) => `<div class="p-0">${description || FALLBACK_MESSAGE.dash}</div>`,
    },
  ];
}
