var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "VehicleSummaryCard d-flex justify-content-between h-auto emobg-background-color-white emobg-border-color-ground emobg-border-1 m-1 emobg-border-radius-small",
      staticStyle: { width: "235px" },
    },
    [
      _c("div", { staticClass: "d-flex flex-grow-1 flex-column p-3" }, [
        _c("span", { staticClass: "emobg-font-weight-bold pb-1" }, [
          _vm._v(" " + _vm._s(_vm.licensePlate) + " "),
        ]),
        _c("span", { staticClass: "pb-1" }, [
          _vm._v(" " + _vm._s(_vm.model) + " "),
        ]),
        _c(
          "div",
          {
            staticClass: "emobg-color-ink-light emobg-font-small",
            attrs: { "data-test-id": `vehicle-location-${_vm.uuid}` },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isFalsyString(_vm.location)
                    ? _vm.FALLBACK_MESSAGE.dash
                    : _vm.location
                ) +
                " "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "d-flex flex-grow-0 p-1" },
        [
          _c(
            "ui-button",
            {
              attrs: {
                "data-test-id": "vehicle-close-button",
                face: _vm.FACES.text,
                color: _vm.GRAYSCALE.inkLight,
                disabled: _vm.disabled,
                square: "",
              },
              on: {
                clickbutton: function ($event) {
                  return _vm.onRemove(_vm.uuid)
                },
              },
            },
            [
              _c("ui-icon", {
                attrs: {
                  icon: _vm.ICONS.removeFilled,
                  size: _vm.ICONS_SIZES.small,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }