var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g({ attrs: { header: null, "backdrop-dismiss": "" } }, _vm.$listeners),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: {
              text: "This cannot be undone",
              title: "Are you sure you want to delete this occurrence?",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: {
              loading:
                _vm.bookingSetOccurrenceStatus.LOADING ||
                _vm.bookingSetStatus.LOADING,
            },
            on: { click: _vm.removeBookingSetOccurrence },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }