var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "SummaryComponent emobg-border-vertical-1 emobg-border-top-1 emobg-border-color-ground-light emobg-background-color-ground-lightest",
    },
    [
      _c(
        "h3",
        {
          staticClass:
            "emobg-font-weight-bold w-100 d-flex justify-content-between align-items-center ml-3",
        },
        [
          _vm._v(" Summary "),
          _c(
            "ui-button",
            {
              staticClass: "my-2 mr-3",
              attrs: { color: _vm.GRAYSCALE.inkLight, face: _vm.FACES.text },
              on: {
                clickbutton: function ($event) {
                  _vm.isSummaryVisible = !_vm.isSummaryVisible
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.isSummaryVisible ? "Hide" : "Show") + " ")]
          ),
        ],
        1
      ),
      _c(
        "MuiCollapse",
        { attrs: { open: _vm.isSummaryVisible } },
        [
          _c("MuiTabs", {
            staticClass: "MuiTabs--tabs-pad",
            attrs: {
              id: "BookingSetsSummaryTabs",
              nested: true,
              tabs: _vm.tabs,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }