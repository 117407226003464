<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { navigationErrorHandler, sentenceCase } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { EditButton, PermissionLink } from '@/components';
import BookingTypeBadge from '@/domains/Carsharing/components/BookingTypeBadge/BookingTypeBadge';
import { BOOKING_TYPES } from '@/domains/Carsharing/const/bookingTypes';
import carsharing from '@/domains/Carsharing/router/CarsharingRouterMap';

import DeleteBookingSetModal from './components/DeleteBookingSetModal';
import UnsavedChangesModal from '../../../UnsavedChangesModal/UnsavedChangesModal';

export default {
  components: {
    BookingTypeBadge,
    PermissionLink,
    EditButton,
    DeleteBookingSetModal,
    UnsavedChangesModal,
  },
  props: {
    hasUnsavedChanges: {
      type: [Boolean, Number],
      default: false,
    },
    request: {
      type: Function,
      default: () => {},
    },
    status: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDeleteModalOpen: false,
      showUnsavedChangesModal: false,
    };
  },
  computed: {
    isStep3() {
      return this.$route.name === get(this, 'carsharing.bookingSets.occurrences');
    },
    isCarsharing() {
      const carsharingTypes = [BOOKING_TYPES.carsharing, BOOKING_TYPES.longDistance];
      return carsharingTypes.includes(this.bookingSet.type);
    },
    statusBadgeColor() {
      return this.STATUS_COLORS[this.bookingSet.status] || this.COLORS.moderate;
    },
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
      bookingSetVehiclesStatus: state => state.bookingSetVehicle.STATUS,
    }),
  },
  created() {
    this.carsharing = carsharing;
    this.BOOKING_TYPES = BOOKING_TYPES;
    this.STATUS_COLORS = {
      draft: 'moderate',
      created: 'warning',
      success: 'success',
      completed: 'ground',
    };
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.crm = crm;
  },
  methods: {
    get,
    sentenceCase,
    redirectToTarget() {
      this.$router.push({
        name: this.targetRoute,
        params: {
          bookingSetUuid: this.bookingSet.uuid,
        },
        query: {
          debug: true,
        },
      }).catch(navigationErrorHandler);
    },
    setTargetRoute(route) {
      this.targetRoute = route;

      if (this.hasUnsavedChanges) {
        this.showUnsavedChangesModal = true;
      } else {
        this.redirectToTarget();
      }
    },
  },
};
</script>
<template>
  <div class="SetupView emobg-background-color-white px-3 py-4">
    <div class="d-flex justify-content-between">
      <div class="row w-100">
        <div class="col-md-6 col-lg-3">
          <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
            Created by
          </span>

          <PermissionLink
            :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
            :to="{
              name: crm.users.detail.index,
              params: {
                userUuid: bookingSet.creatorUuid,
              },
            }"
            target="_blank"
          >
            {{ bookingSet.creatorName || FALLBACK_MESSAGE.dash }}
          </PermissionLink>
        </div>

        <div class="col-md-6 col-lg-3">
          <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
            Status
          </span>
          <ui-badge :color="statusBadgeColor">
            {{ sentenceCase(bookingSet.status) || FALLBACK_MESSAGE.dash }}
          </ui-badge>
        </div>
      </div>

      <ui-button
        :face="FACES.text"
        :color="COLORS.danger"
        square
        @clickbutton="isDeleteModalOpen = true"
      >
        <ui-icon
          :icon="ICONS.trash"
          :color="COLORS.danger"
        />
      </ui-button>
    </div>
    <hr class="emobg-border-top-1 emobg-border-color-ground-light my-4">

    <div class="row">
      <div class="d-flex justify-content-between col-12 pb-4">
        <div>
          <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
            Name*
          </span>
          <span class="emobg-font-small">
            {{ bookingSet.name || FALLBACK_MESSAGE.dash }}
          </span>
        </div>
        <div>
          <EditButton @click="setTargetRoute(get(carsharing, 'bookingSets.generalInformation'))" />
        </div>
      </div>

      <div class="col-md-6 col-lg-3 pb-4">
        <div class="emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
          Booking type*
        </div>
        <BookingTypeBadge :type="bookingSet.type" />
      </div>

      <div
        v-if="bookingSet.type !== BOOKING_TYPES.unavailability"
        class="col-md-6 col-lg-3 pb-4"
      >
        <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
          User*
        </span>

        <PermissionLink
          :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
          :to="{
            name: crm.users.detail.bookings,
            params: {
              userUuid: bookingSet.userUuid,
            },
          }"
          target="_blank"
        >
          {{ bookingSet.userName || FALLBACK_MESSAGE.dash }}
        </PermissionLink>
      </div>

      <div
        v-if="bookingSet.type !== BOOKING_TYPES.unavailability"
        class="col-md-6 col-lg-3 pb-4"
      >
        <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
          Profile*
        </span>
        <span class="emobg-font-small text-capitalize">
          {{ bookingSet.userProfileAlias || FALLBACK_MESSAGE.dash }}
        </span>
      </div>

      <div
        v-if="isCarsharing"
        class="col-md-6 col-lg-3 pb-4"
      >
        <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
          Insurance type*
        </span>
        <span class="emobg-font-small">
          {{ bookingSet.insuranceName || FALLBACK_MESSAGE.dash }}
        </span>
      </div>

      <div
        v-if="bookingSet.type === BOOKING_TYPES.intervention"
        class="col-md-6 col-lg-3 pb-4"
      >
        <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
          Intervention name*
        </span>
        <span class="emobg-font-small">
          {{ bookingSet.interventionName || FALLBACK_MESSAGE.dash }}
        </span>
      </div>

      <div
        v-if="get(bookingSet, 'companyCostAllocations.length')"
        class="col-md-6 col-lg-3 pb-4"
      >
        <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
          Cost allocation
        </span>
        <div
          v-for="costAllocation in bookingSet.companyCostAllocations"
          :key="costAllocation.childUuid"
        >
          <div>
            <span class="emobg-font-small pb-1 emobg-font-weight-bold emobg-color-ink">
              {{ costAllocation.name }}:
            </span>
            <span class="emobg-font-small">
              {{ costAllocation.codeValue || costAllocation.customValue }}
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-6 pb-4">
        <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
          Description
        </span>
        <span class="emobg-font-small">
          {{ bookingSet.description || FALLBACK_MESSAGE.dash }}
        </span>
      </div>
    </div>

    <div v-if="isStep3">
      <hr class="emobg-border-top-1 emobg-border-color-ground-light my-4">
      <div class="row">
        <div class="d-flex justify-content-between col-12 pb-4">
          <div>
            <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
              Vehicles
            </span>
            <div
              v-for="vehicle in bookingSet.vehicles"
              :key="vehicle.vehicleUuid"
              class="emobg-font-small pb-1"
            >
              <RouterLink
                :to="`/fleet/vehicles/${vehicle.vehicleUuid}/details`"
                class="emobg-link color-main emobg-font-small"
                target="_blank"
              >
                {{ vehicle.licensePlate }}
              </RouterLink>
              {{ vehicle.brand }} {{ vehicle.model }}
            </div>
          </div>
          <div>
            <EditButton @click="setTargetRoute(get(carsharing, 'bookingSets.selectVehicles'))" />
          </div>
        </div>
      </div>
    </div>

    <DeleteBookingSetModal
      v-if="bookingSet.uuid && isDeleteModalOpen"
      :booking-set-uuid="bookingSet.uuid"
      :status="bookingSet.status"
      @closeModal="() => isDeleteModalOpen = false"
      @modal-closed="() => isDeleteModalOpen = false"
    />
    <UnsavedChangesModal
      v-if="bookingSet.uuid && showUnsavedChangesModal"
      :status="status"
      :loading="loading"
      :close="() => showUnsavedChangesModal = false"
      :discard="redirectToTarget"
      :save="request"
      title="Save changes before editing?"
      @closeModal="() => showUnsavedChangesModal = false"
      @modal-closed="() => showUnsavedChangesModal = false"
    />
  </div>
</template>
