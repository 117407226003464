var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "AfterBeforeTemplate" }, [
    !_vm.isUndefined(_vm.bookingSet.name)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" Name ")]
          ),
          _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
            _vm._v(
              " " +
                _vm._s(_vm.bookingSet.name || _vm.FALLBACK_MESSAGE.dash) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.type)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" Booking type ")]
          ),
          _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.sentenceCase(_vm.bookingSet.type) ||
                    _vm.FALLBACK_MESSAGE.dash
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.user_full_name)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" User name ")]
          ),
          _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.bookingSet.user_full_name || _vm.FALLBACK_MESSAGE.dash
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.user_profile_alias)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" Profile ")]
          ),
          _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.sentenceCase(_vm.bookingSet.user_profile_alias) ||
                    _vm.FALLBACK_MESSAGE.dash
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.insurance_name)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" Insurance type ")]
          ),
          _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.bookingSet.insurance_name || _vm.FALLBACK_MESSAGE.dash
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.company_cost_allocations)
      ? _c(
          "div",
          { staticClass: "pb-2" },
          [
            _c(
              "span",
              { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
              [_vm._v(" Cost allocation(s) ")]
            ),
            _vm.get(_vm.bookingSet, "company_cost_allocations.length")
              ? _vm._l(
                  _vm.bookingSet.company_cost_allocations,
                  function (costAllocation, index) {
                    return _c("div", { key: index, staticClass: "pb-2" }, [
                      _c(
                        "span",
                        { staticClass: "emobg-caption-2 pb-1 emobg-color-ink" },
                        [_vm._v(" " + _vm._s(costAllocation.name) + ": ")]
                      ),
                      _c("span", { staticClass: "emobg-caption-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              costAllocation.code_value ||
                                costAllocation.custom_value
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  }
                )
              : _c("span", [
                  _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                ]),
          ],
          2
        )
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.description)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" Description ")]
          ),
          _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.bookingSet.description || _vm.FALLBACK_MESSAGE.dash
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.status)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" Status ")]
          ),
          _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.sentenceCase(_vm.bookingSet.status) ||
                    _vm.FALLBACK_MESSAGE.dash
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.vehicles)
      ? _c("div", [
          _c(
            "span",
            { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
            [_vm._v(" Vehicles ")]
          ),
          !_vm.isEmpty(_vm.bookingSet.vehicles)
            ? _c(
                "div",
                _vm._l(_vm.bookingSet.vehicles, function (licensePlate) {
                  return _c(
                    "span",
                    {
                      key: licensePlate,
                      staticClass: "d-block emobg-caption-1 pb-2",
                    },
                    [_vm._v(" " + _vm._s(licensePlate) + " ")]
                  )
                }),
                0
              )
            : _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
                _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
              ]),
        ])
      : _vm._e(),
    !_vm.isUndefined(_vm.bookingSet.occurrences)
      ? _c(
          "div",
          [
            _c(
              "span",
              { staticClass: "d-block emobg-caption-2 pb-1 emobg-color-ink" },
              [_vm._v(" Occurrences ")]
            ),
            _vm.get(_vm.bookingSet, "occurrences.length")
              ? _vm._l(_vm.bookingSet.occurrences, function (ocurrence, index) {
                  return _c("div", { key: index, staticClass: "pb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.reformatDateTime(
                            ocurrence.start,
                            _vm.DATE_FORMAT.defaultExtended,
                            _vm.operatorTimezone
                          )
                        ) +
                        " to " +
                        _vm._s(
                          _vm.reformatDateTime(
                            ocurrence.end,
                            _vm.DATE_FORMAT.defaultExtended,
                            _vm.operatorTimezone
                          )
                        ) +
                        " "
                    ),
                  ])
                })
              : _c("span", { staticClass: "d-block emobg-caption-1 pb-2" }, [
                  _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }