<script>
import get from 'lodash/get';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

import carsharing from '../../../../router/CarsharingRouterMap';

export default {
  name: 'BookingSetLinkTemplate',
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bookingSetName() {
      return this.result.name || FALLBACK_MESSAGE.dash;
    },
    routeName() {
      return get(this, 'result.vehicles.length') ? carsharing.bookingSets.occurrences : carsharing.bookingSets.selectVehicles;
    },
  },
  created() {
    this.carsharing = carsharing;
  },
};
</script>
<template>
  <ui-tooltip
    :key="bookingSetName"
    :tooltip="bookingSetName"
    data-test-id="booking_set-tooltip"
  >
    <RouterLink
      :to="{
        name: routeName,
        params: {
          bookingSetUuid: result.uuid
        }
      }"
      class="emobg-link-primary emobg-body-2"
      data-test-id="link"
    >
      {{ bookingSetName }}
    </RouterLink>
  </ui-tooltip>
</template>
