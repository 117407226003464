<script>
import { MuiTable } from '@emobg/vue-base';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { AUDIT_ENTITIES } from '@domains/Shared/constants/audit.const';
import carsharing from '@/domains/Carsharing/store/CarsharingModuleMap';
import { contentCells } from './config/contentCells';

export default {
  components: {
    MuiTable,
  },
  computed: {
    ...mapState(carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
    }),
    ...mapState(DOMAINS_MODEL.shared.audit, {
      bokingSetsAuditData: state => state.data,
      isBokingSetsAuditDataLoading: state => state.isLoading,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
  },
  async created() {
    this.contentCells = contentCells.bind(this)();
    await this.getAuditData({ primaryEntity: AUDIT_ENTITIES.bookingSet, primaryId: this.bookingSet.id });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.shared.audit, [
      'getAuditData',
    ]),
  },
};
</script>
<template>
  <div class="AuditView emobg-background-color-white px-3 py-4">
    <ui-card
      nested
      class="d-block mt-3 ml-2"
    >
      <MuiTable
        :data-set="bokingSetsAuditData"
        :content-cells="contentCells"
        :is-loading="isBokingSetsAuditDataLoading"
        :no-data-label="FALLBACK_MESSAGE.dash"
        data-test-id="list"
      />
    </ui-card>
  </div>
</template>
