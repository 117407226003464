<script>
import get from 'lodash/get';
import { GenericModalComponent } from '@/components';

export default {
  components: {
    GenericModalComponent,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default: () => ({}),
    },
    save: {
      type: Function,
      default: () => {},
    },
    discard: {
      type: Function,
      default: () => {},
    },
    close: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    async saveAndClose() {
      await this.save();

      if (!get(this, 'status.ERROR', false)) {
        this.discard();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    backdrop-dismiss
    v-on="$listeners"
  >
    <template slot="body">
      <span
        v-if="title"
        class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large pb-3 emobg-color-ink"
        v-html="title"
      />
    </template>
    <template slot="footer">
      <div class="d-flex align-content-center justify-content-end w-100">
        <ui-button
          :color="COLORS.danger"
          :face="FACES.outline"
          class="mr-2"
          @clickbutton="discard"
        >
          Discard
        </ui-button>

        <ui-button
          :loading="status.LOADING || loading"
          @clickbutton="saveAndClose"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
