<script>
import toLower from 'lodash/toLower';
import { mapActions, mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    bookingSetUuid: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      keepLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSetStatus: state => state.bookingSet.STATUS,
    }),
    text() {
      return toLower(this.status) === 'draft' ? 'This cannot be undone' : 'Only booking sets with status DRAFT can be deleted';
    },
    title() {
      return toLower(this.status) === 'draft' ? 'Are you sure you want to delete this booking set?' : '<span class="emobg-color-danger emobg-font-weight-bold">Cannot be deleted!</span>';
    },
  },
  methods: {
    toLower,
    async removeBookingSet() {
      this.keepLoading = true;
      await this.deleteBookingSet({
        bookingSetUuid: this.bookingSetUuid,
      });

      if (!this.bookingSetStatus.ERROR) {
        setTimeout(() => {
          this.keepLoading = false;
          this.$router.push('/fleet/availability/booking-sets').catch(navigationErrorHandler);
        }, 1500);
      } else {
        this.keepLoading = false;
      }
    },
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'deleteBookingSet',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="toLower(status) === 'draft' ? SIZES.medium : SIZES.small"
    class="DeleteBookingSet"
    backdrop-dismiss
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="text"
        :title="title"
      />
    </template>
    <template slot="footer">
      <template v-if="toLower(status) === 'draft'">
        <CancelButton @click="$emit('closeModal')" />
        <DeleteButton
          :loading="bookingSetStatus.LOADING || keepLoading"
          @click="removeBookingSet"
        />
      </template>
      <template v-else>
        <ui-button
          class="mr-2"
          @clickbutton="$emit('closeModal')"
        >
          OK
        </ui-button>
      </template>
    </template>
  </GenericModalComponent>
</template>
