import { DATE_FORMAT, formatUtc, sentenceCase } from '@emobg/web-utils';
import BookingSetAuditComponent from '../components/BookingSetAuditComponent';

export function contentCells() {
  return [
    {
      property: 'auditAction',
      title: 'Action',
      transformValue: value => sentenceCase(value.replace('booking_set_', '')),
    },
    {
      property: 'createdAtTs',
      title: 'Date',
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, this.operatorTimezone),
    },
    {
      property: 'userName',
      title: 'User',
      width: 200,
    },
    {
      component: BookingSetAuditComponent,
      folded: true,
      props: result => ({
        before: result.valueBefore,
        after: result.valueAfter,
      }),
    },
  ];
}
