<script>
import get from 'lodash/get';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import omit from 'lodash/omit';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import SelectVehicles from './SelectVehicles';
import SummaryComponent from '../SummaryComponent/SummaryComponent';
import { BOOKING_SET_STATUS } from '../../const/bookingSets.const';

export default {
  components: {
    SelectVehicles,
    SummaryComponent,
  },
  data() {
    return {
      vehicles: [],
    };
  },
  computed: {
    isEditing() {
      return get(this, 'bookingSet.status') === BOOKING_SET_STATUS.created;
    },
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
      bookingSetVehiclesStatus: state => state.bookingSetVehicle.STATUS,
    }),
  },
  async created() {
    await this.getBookingSet({ bookingSetUuid: get(this, '$route.params.bookingSetUuid') });
    const bookingSetVehicles = get(this, 'bookingSet.vehicles', []);
    this.vehicles = map(bookingSetVehicles, vehicle => omit(vehicle, ['uuid']));
  },
  methods: {
    get,
    removeVehicle(vehicleUuid) {
      const index = findIndex(this.vehicles, { vehicleUuid });
      this.vehicles.splice(index, 1);
    },
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'getBookingSet',
    ]),
  },
};
</script>
<template>
  <div class="SelectVehiclesView">
    <h2 class="mb-2">
      {{ isEditing ? 'Edit booking set' : 'Create booking set' }}
    </h2>
    <SummaryComponent
      :has-unsaved-changes="get($refs, 'selectVehicles.hasUnsavedChanges', false)"
      :request="get($refs, 'selectVehicles.request', () => {})"
      :status="bookingSetVehiclesStatus"
    />
    <SelectVehicles
      ref="selectVehicles"
      :vehicles="vehicles"
      :is-editing="isEditing"
      @remove-vehicle="removeVehicle"
    />
  </div>
</template>
