<script>
import get from 'lodash/get';

import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    occurrence: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
      bookingSetStatus: state => state.bookingSet.STATUS,
      bookingSetOccurrenceStatus: state => state.bookingSetOccurrence.STATUS,
    }),
  },
  methods: {
    async removeBookingSetOccurrence() {
      await this.deleteBookingSetOccurrence({
        bookingSetUuid: this.bookingSet.uuid,
        occurrenceUuid: this.occurrence.uuid,
      });

      if (!this.bookingSetOccurrenceStatus.ERROR) {
        await this.getBookingSet({ bookingSetUuid: get(this, '$route.params.bookingSetUuid') });
        this.close();
        this.$emit('deleteOccurence');
      }
    },
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'deleteBookingSetOccurrence',
      'getBookingSet',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    backdrop-dismiss
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        text="This cannot be undone"
        title="Are you sure you want to delete this occurrence?"
      />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="bookingSetOccurrenceStatus.LOADING || bookingSetStatus.LOADING"
        @click="removeBookingSetOccurrence"
      />
    </template>
  </GenericModalComponent>
</template>
