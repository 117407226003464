var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "BookingSetAuditComponent" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("div", { staticClass: "emobg-caption-2 pb-2 emobg-color-ink" }, [
            _vm._v(" Before "),
          ]),
          _vm.isEmpty(_vm.before)
            ? _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")])
            : _vm.areSameValues
            ? _c("div", [_vm._v(" No data change registered ")])
            : _c("AfterBeforeTemplate", {
                attrs: { "booking-set": _vm.changedItem(_vm.before) },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("div", { staticClass: "emobg-caption-2 pb-2 emobg-color-ink" }, [
            _vm._v(" After "),
          ]),
          _vm.isEmpty(_vm.after)
            ? _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")])
            : _vm.areSameValues
            ? _c("div", [_vm._v(" No data change registered ")])
            : _c("AfterBeforeTemplate", {
                attrs: { "booking-set": _vm.changedItem(_vm.after) },
              }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }