<script>
import replace from 'lodash/replace';
import get from 'lodash/get';

import { toRefs } from 'vue';
import moment from 'moment-timezone';
import { mapActions, mapState } from 'vuex';
import createStore from '@/store';
import { useAvailabilityDates } from '@domains/Carsharing/Availability/composable/AvailabilityDates';
import { MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import { DATE_FORMAT, DATE_FORMAT_KEYS, TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { duration } from '@/domains/Carsharing/utils/duration';
import { GenericModalComponent } from '@/components';
import { DATE_INPUT_VALIDATIONS } from '@/utils';

export default {
  components: {
    GenericModalComponent,
    MuiDatePicker,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    occurrence: {
      type: Object,
      default: null,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    // TODO: Replace using useStore util https://europcarmobility.atlassian.net/browse/CF-207
    const store = createStore();
    const activeOperatorPath = `state.${replace(DOMAINS_MODEL.app.userAccount, /\//g, '.')}.operators.active`;
    const activeOperatorTimezone = get(store, `${activeOperatorPath}.timezone`, TIME_ZONE.default);
    const { occurrence } = toRefs(props);

    const initialAvailabilityDatesData = {
      start: occurrence.value ? moment.utc(occurrence.value.start).tz(activeOperatorTimezone) : null,
      end: occurrence.value ? moment.utc(occurrence.value.end).tz(activeOperatorTimezone) : null,
      minStart: () => moment(),
      minEnd: startDate => moment(startDate).add(1, 'minute'),
      entity: occurrence.value,
      minutesRange: 15,
    };

    const {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    } = useAvailabilityDates(initialAvailabilityDatesData);

    return {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    };
  },
  data() {
    return {
      isFormValid: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
      bookingSetStatus: state => state.bookingSet.STATUS,
      bookingSetOccurrence: state => state.bookingSetOccurrence.data,
      bookingSetOccurrenceStatus: state => state.bookingSetOccurrence.STATUS,
    }),
    title() {
      const action = this.occurrence ? 'Edit' : 'Create';
      return `${action} occurrence`;
    },
    dataToSend() {
      return {
        bookingSetUuid: get(this, 'bookingSet.uuid'),
        occurrenceUuid: get(this, 'occurrence.uuid'),
        request: {
          start: this.start ? this.start.utc().seconds(0).format(DATE_FORMAT.filter) : this.start,
          end: this.end ? this.end.utc().seconds(0).format(DATE_FORMAT.filter) : this.end,
        },
      };
    },
    durationOfOcurrence() {
      return duration(moment(this.start).seconds(0), moment(this.end).seconds(0));
    },
  },
  watch: {
    start: {
      handler(newDate) {
        if (newDate && !this.end) {
          this.setEnd(moment(newDate).add(1, 'minute'));
        }
      },
    },
  },
  created() {
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.minutesInterval = 15;
    this.duration = duration;
    this.headerConfig = {
      isClosable: false,
    };

    this.validDateRange = {
      start: moment(),
      end: moment().add(10, 'years'),
    };
  },
  methods: {
    clearDates() {
      this.start = null;
      this.end = null;
    },
    async sendRequest() {
      const request = this.occurrence ? this.putBookingSetOccurrence : this.postBookingSetOccurrences;
      await request(this.dataToSend);

      if (!this.bookingSetOccurrenceStatus.ERROR) {
        await this.getBookingSet({ bookingSetUuid: get(this, '$route.params.bookingSetUuid') });
        this.close(!this.occurrence ? this.bookingSetOccurrence.uuid : null);
      }
    },
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'postBookingSetOccurrences',
      'putBookingSetOccurrence',
      'getBookingSet',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="title"
    :header="{ isClosable: true }"
    class="OccurrenceFormModal"
    v-on="$listeners"
  >
    <template slot="body">
      <MuiValidationWrapper
        class="OccurrenceForm row"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <div class="col-12 emobg-font-weight-bold pb-2">
          Select start and end date of your bookings
        </div>
        <div class="col-sm-6 mb-1">
          <MuiDatePicker
            v-validate="{
              isRequired: true,
              isValidDate: DATE_INPUT_VALIDATIONS.validDate,
            }"
            :date="start"
            :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
            :valid-date-range="validDateRange"
            :minutes-interval="minutesInterval"
            label="Start date"
            class="w-100"
            @update:date="setStart"
          />
        </div>
        <div class="col-sm-6 mb-1">
          <MuiDatePicker
            v-validate="{
              isRequired: true,
              isValidDate: DATE_INPUT_VALIDATIONS.validDate,
            }"
            :date="end"
            :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
            :disabled="!start"
            :valid-date-range="validDateRange"
            :minutes-interval="minutesInterval"
            label="End date"
            class="w-100"
            @update:date="setEnd"
          />
        </div>
        <div class="col-12">
          <p
            v-if="start && end"
            class="emobg-font-weight-semibold"
          >
            <span class="emobg-font-weight-bold">Duration:</span> {{ durationOfOcurrence }}
          </p>
          <ui-button
            v-if="!occurrence"
            :face="FACES.text"
            :square="true"
            @clickbutton="clearDates"
          >
            Clear
          </ui-button>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex align-content-center justify-content-end w-100">
        <ui-button
          :color="GRAYSCALE.inkLight"
          :face="FACES.text"
          class="mr-2"
          @clickbutton="close()"
        >
          Cancel
        </ui-button>

        <ui-button
          :disabled="!isFormValid"
          :loading="bookingSetOccurrenceStatus.LOADING || bookingSetStatus.LOADING"
          @clickbutton="sendRequest"
        >
          {{ occurrence ? 'Save' : 'Create' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
