var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingSetList__CitiesTemplate",
      attrs: { "data-test-id": "booking_sets-cities_template" },
    },
    [
      _vm.cities.length
        ? _c(
            "div",
            {
              staticClass: "d-flex",
              attrs: { title: _vm.citiesText, "data-test-id": "cities" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-ellipsis",
                  attrs: { "data-test-id": "text" },
                },
                [_vm._v(" " + _vm._s(_vm.citiesText) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cities.length,
                      expression: "cities.length",
                    },
                  ],
                  staticClass: "pl-1",
                  attrs: { "data-test-id": "count" },
                },
                [_vm._v(" (" + _vm._s(_vm.cities.length) + ") ")]
              ),
            ]
          )
        : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }