<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import { sentenceCase, snakeCaseKeys } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { PermissionLink } from '@/components';
import { getMessageText } from '@/components/GlobalMessaging/GlobalMessagingComponent';

import { BOOKING_TYPES, CELL_STATUS } from './const';
import UpdatedInfoCard from './UpdatedInfoCard';

export default {
  components: { UpdatedInfoCard, PermissionLink },
  props: {
    cell: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showUpdateInfoCard: false,
    };
  },
  computed: {
    isPending() {
      return [
        CELL_STATUS.pending,
        CELL_STATUS.skipped,
      ].includes(this.cell.status);
    },
    isBooked() {
      return [
        CELL_STATUS.booked,
        CELL_STATUS.modified,
        CELL_STATUS.cancelled,
        CELL_STATUS.finished,
      ].includes(this.cell.status);
    },
    customErrorDataSnakeCase() {
      const data = get(this, 'cell.errorMetadata');
      const errorObject = cloneDeep(data);
      errorObject.data = get(errorObject, 'custom', errorObject.data);
      return snakeCaseKeys(errorObject);
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
  },
  created() {
    this.CELL_STATUS = CELL_STATUS;
    this.BOOKING_TYPES = BOOKING_TYPES;
    this.carsharingRoutes = carsharingRoutes;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
  },
  methods: {
    get,
    sentenceCase,
    getMessageText,
  },
};
</script>
<template>
  <div class="OccurrenceCellTemplate d-flex flex-grow-1">
    <div v-if="isPending">
      Create booking
    </div>
    <div
      v-else-if="cell.status === CELL_STATUS.failed"
      class="d-flex flex-grow-1 justify-content-between align-items-center"
    >
      <div class="d-flex flex-column">
        <span class="emobg-color-danger">
          Booking failed
        </span>
        <div
          class="OccurrenceCellTemplate__error-wrapper"
          v-html="getMessageText(customErrorDataSnakeCase, $t, operatorTimezone)"
        />
      </div>
    </div>
    <div
      v-else-if="isBooked"
      class="d-flex flex-grow-1 justify-content-between align-items-center"
    >
      <div class="d-flex flex-column">
        <div class="pb-1">
          <span
            class="emobg-color-success"
            style="opacity: 0.5;"
          >
            Booking created
          </span>
          <PermissionLink
            :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingBookings]"
            :to="{
              name: carsharingRoutes.bookings.detail.index,
              params: {
                bookingUuid: get(cell, 'bookingUuid'),
              },
            }"
          >
            #{{ get(cell, 'bookingId') }}
          </PermissionLink>
        </div>
        <div v-if="cell.updateMetadata">
          <span class="emobg-color-moderate-light"> · Something has changed </span>
          <a
            class="emobg-link-primary emobg-body-2"
            @click.prevent="showUpdateInfoCard = true"
          >
            show more
          </a>
          <UpdatedInfoCard
            v-if="showUpdateInfoCard"
            :close="() => showUpdateInfoCard = false"
            :metadata="cell.updateMetadata"
          />
        </div>
      </div>
      <div>
        <ui-badge
          :color="GRAYSCALE.ground"
          class="d-flex align-items-center mb-1"
        >
          <ui-icon
            :icon="ICONS.systemStatus"
            :size="ICONS_SIZES.xSmall"
            class="d-inline-block p-1"
          />
          {{ sentenceCase(cell.bookingDomainStatus) }}
        </ui-badge>

        <ui-badge
          :color="GRAYSCALE.ground"
          class="d-flex align-items-center mb-1"
        >
          <ui-icon
            :icon="ICONS.vehicleStatus"
            :size="ICONS_SIZES.xSmall"
            class="d-inline-block p-1"
          />
          {{ type === BOOKING_TYPES.unavailability ? FALLBACK_MESSAGE.notAvailable : sentenceCase(cell.bookingFleetStatus) }}
        </ui-badge>
      </div>
    </div>
    <div v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
<style lang="scss">
  .OccurrenceCellTemplate {
    &__error-wrapper {
      line-height: 1.125rem;

      a {
        color: #0086e6;
        text-decoration: none;
        pointer-events: all;
        cursor: pointer;
      }
    }
  }
</style>
