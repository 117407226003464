var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteBookingSet",
        attrs: {
          header: null,
          size:
            _vm.toLower(_vm.status) === "draft"
              ? _vm.SIZES.medium
              : _vm.SIZES.small,
          "backdrop-dismiss": "",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: { text: _vm.text, title: _vm.title },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.toLower(_vm.status) === "draft"
            ? [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: {
                    loading: _vm.bookingSetStatus.LOADING || _vm.keepLoading,
                  },
                  on: { click: _vm.removeBookingSet },
                }),
              ]
            : [
                _c(
                  "ui-button",
                  {
                    staticClass: "mr-2",
                    on: {
                      clickbutton: function ($event) {
                        return _vm.$emit("closeModal")
                      },
                    },
                  },
                  [_vm._v(" OK ")]
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }