var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "OccurrenceFormModal",
        attrs: { title: _vm.title, header: { isClosable: true } },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            {
              staticClass: "OccurrenceForm row",
              on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) },
            },
            [
              _c("div", { staticClass: "col-12 emobg-font-weight-bold pb-2" }, [
                _vm._v(" Select start and end date of your bookings "),
              ]),
              _c(
                "div",
                { staticClass: "col-sm-6 mb-1" },
                [
                  _c("MuiDatePicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isValidDate: _vm.DATE_INPUT_VALIDATIONS.validDate,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      date: _vm.start,
                      "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                      "valid-date-range": _vm.validDateRange,
                      "minutes-interval": _vm.minutesInterval,
                      label: "Start date",
                    },
                    on: { "update:date": _vm.setStart },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-sm-6 mb-1" },
                [
                  _c("MuiDatePicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isValidDate: _vm.DATE_INPUT_VALIDATIONS.validDate,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      date: _vm.end,
                      "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                      disabled: !_vm.start,
                      "valid-date-range": _vm.validDateRange,
                      "minutes-interval": _vm.minutesInterval,
                      label: "End date",
                    },
                    on: { "update:date": _vm.setEnd },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _vm.start && _vm.end
                    ? _c("p", { staticClass: "emobg-font-weight-semibold" }, [
                        _c("span", { staticClass: "emobg-font-weight-bold" }, [
                          _vm._v("Duration:"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.durationOfOcurrence) + " "),
                      ])
                    : _vm._e(),
                  !_vm.occurrence
                    ? _c(
                        "ui-button",
                        {
                          attrs: { face: _vm.FACES.text, square: true },
                          on: { clickbutton: _vm.clearDates },
                        },
                        [_vm._v(" Clear ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-content-center justify-content-end w-100",
          },
          [
            _c(
              "ui-button",
              {
                staticClass: "mr-2",
                attrs: { color: _vm.GRAYSCALE.inkLight, face: _vm.FACES.text },
                on: {
                  clickbutton: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "ui-button",
              {
                attrs: {
                  disabled: !_vm.isFormValid,
                  loading:
                    _vm.bookingSetOccurrenceStatus.LOADING ||
                    _vm.bookingSetStatus.LOADING,
                },
                on: { clickbutton: _vm.sendRequest },
              },
              [_vm._v(" " + _vm._s(_vm.occurrence ? "Save" : "Create") + " ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }