export const CELL_STATUS = {
  booked: 'booked',
  failed: 'failed',
  pending: 'pending',
  skipped: 'skipped',
  modified: 'modified',
  cancelled: 'cancelled',
  finished: 'finished',
};

export const BOOKING_TYPES = {
  unavailability: 'unavailability',
};
