<script>
import find from 'lodash/find';
import { ClickOutside } from '@emobg/vue-base';
import { DATE_FORMAT, reformatDateTime } from '@emobg/web-utils';
import { mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  directives: {
    ClickOutside,
  },
  props: {
    metadata: {
      type: [Array, Object],
      default: () => [],
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    vehicleChanged() {
      return find(this.metadata, { key: 'vehicle' });
    },
    datesChanged() {
      return find(this.metadata, { key: 'dates' });
    },
    insuranceChanged() {
      return find(this.metadata, { key: 'insurance' });
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
  },
  created() {
    this.reformatDateTime = reformatDateTime;
    this.DATE_FORMAT = DATE_FORMAT;
  },
};
</script>
<template>
  <div
    v-click-outside="close"
    class="emobg-border-1 emobg-border-color-ground-light shadow-s
           emobg-background-color-white
           position-relative
           p-2 mt-n6 ml-n3"
  >
    <ui-button
      :face="FACES.text"
      square
      class="float-right"
      @clickbutton="close"
    >
      <ui-icon
        :icon="ICONS.cross"
        :color="GRAYSCALE.ground"
        :size="ICONS_SIZES.small"
      />
    </ui-button>
    <div class="d-flex flex-column p-3">
      <div
        v-if="vehicleChanged"
        class="d-flex flex-column"
      >
        <span class="emobg-font-weight-bold">New vehicle: </span>
        <span> {{ vehicleChanged.values.licensePlate }} </span>
      </div>
      <div
        v-if="datesChanged"
        class="d-flex flex-column pb-2"
      >
        <span class="emobg-font-weight-bold pb-1"> New dates: </span>
        <span>
          {{ reformatDateTime(datesChanged.values.startDate, DATE_FORMAT.defaultExtended, operatorTimezone) }}
          <br>
          {{ reformatDateTime(datesChanged.values.endDate, DATE_FORMAT.defaultExtended, operatorTimezone) }}
        </span>
      </div>
      <div
        v-if="insuranceChanged"
        class="d-flex flex-column pb-2"
      >
        <span class="emobg-font-weight-bold pb-1"> New insurance: </span>
        <span>
          {{ insuranceChanged.values.name }}
        </span>
      </div>
    </div>
  </div>
</template>
