<script>
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  DATE_FORMAT,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
export default {
  props: {
    bookingSet: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
  },
  created() {
    this.sentenceCase = sentenceCase;
    this.reformatDateTime = reformatDateTime;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    isUndefined,
    get,
    isEmpty,
  },
};
</script>
<template>
  <div class="AfterBeforeTemplate">
    <div v-if="!isUndefined(bookingSet.name)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Name
      </span>
      <span class="d-block emobg-caption-1 pb-2">
        {{ bookingSet.name || FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.type)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Booking type
      </span>
      <span class="d-block emobg-caption-1 pb-2">
        {{ sentenceCase(bookingSet.type) || FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.user_full_name)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        User name
      </span>
      <span class="d-block emobg-caption-1 pb-2">
        {{ bookingSet.user_full_name || FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.user_profile_alias)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Profile
      </span>
      <span class="d-block emobg-caption-1 pb-2">
        {{ sentenceCase(bookingSet.user_profile_alias) || FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.insurance_name)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Insurance type
      </span>
      <span class="d-block emobg-caption-1 pb-2">
        {{ bookingSet.insurance_name || FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div
      v-if="!isUndefined(bookingSet.company_cost_allocations)"
      class="pb-2"
    >
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Cost allocation(s)
      </span>
      <template v-if="get(bookingSet, 'company_cost_allocations.length')">
        <div
          v-for="(costAllocation, index) in bookingSet.company_cost_allocations"
          :key="index"
          class="pb-2"
        >
          <span class="emobg-caption-2 pb-1 emobg-color-ink">
            {{ costAllocation.name }}:
          </span>
          <span class="emobg-caption-1">
            {{ costAllocation.code_value || costAllocation.custom_value }}
          </span>
        </div>
      </template>
      <span v-else>
        {{ FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.description)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Description
      </span>
      <span class="d-block emobg-caption-1 pb-2">
        {{ bookingSet.description || FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.status)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Status
      </span>
      <span class="d-block emobg-caption-1 pb-2">
        {{ sentenceCase(bookingSet.status) || FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.vehicles)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Vehicles
      </span>
      <div v-if="!isEmpty(bookingSet.vehicles)">
        <span
          v-for="licensePlate in bookingSet.vehicles"
          :key="licensePlate"
          class="d-block emobg-caption-1 pb-2"
        >
          {{ licensePlate }}
        </span>
      </div>
      <span
        v-else
        class="d-block emobg-caption-1 pb-2"
      >
        {{ FALLBACK_MESSAGE.dash }}
      </span>
    </div>
    <div v-if="!isUndefined(bookingSet.occurrences)">
      <span class="d-block emobg-caption-2 pb-1 emobg-color-ink">
        Occurrences
      </span>
      <template v-if="get(bookingSet, 'occurrences.length')">
        <div
          v-for="(ocurrence, index) in bookingSet.occurrences"
          :key="index"
          class="pb-2"
        >
          {{ reformatDateTime(ocurrence.start, DATE_FORMAT.defaultExtended, operatorTimezone) }}
          to
          {{ reformatDateTime(ocurrence.end, DATE_FORMAT.defaultExtended, operatorTimezone) }}
        </div>
      </template>
      <span
        v-else
        class="d-block emobg-caption-1 pb-2"
      >
        {{ FALLBACK_MESSAGE.dash }}
      </span>
    </div>
  </div>
</template>
