<script>
import { MuiCollapse } from '@emobg/motion-ui/v1';
import { MuiTabs } from '@emobg/vue-base';

import AuditView from './components/AuditView/AuditView';
import SetupView from './components/SetupView/SetupView';

export default {
  components: {
    MuiCollapse,
    MuiTabs,
  },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSummaryVisible: true,
    };
  },
  created() {
    this.tabs = [
      {
        label: 'Setup',
        content: [{
          component: SetupView,
        }],
      },
      {
        label: 'Audit',
        content: [{
          component: AuditView,
        }],
      },
    ];
    this.isSummaryVisible = this.open;
  },
};
</script>
<template>
  <div class="SummaryComponent emobg-border-vertical-1 emobg-border-top-1 emobg-border-color-ground-light emobg-background-color-ground-lightest">
    <h3 class="emobg-font-weight-bold w-100 d-flex justify-content-between align-items-center ml-3">
      Summary
      <ui-button
        :color="GRAYSCALE.inkLight"
        :face="FACES.text"
        class="my-2 mr-3"
        @clickbutton="isSummaryVisible = !isSummaryVisible"
      >
        {{ isSummaryVisible ? 'Hide' : 'Show' }}
      </ui-button>
    </h3>

    <MuiCollapse :open="isSummaryVisible">
      <MuiTabs
        id="BookingSetsSummaryTabs"
        :nested="true"
        :tabs="tabs"
        class="MuiTabs--tabs-pad"
      />
    </MuiCollapse>
  </div>
</template>

<style lang="scss" scoped>
.MuiTabs {
  &--tabs-pad > div:first-child {
    padding: 0 16px;
  }
}
</style>
