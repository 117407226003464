<script>
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import each from 'lodash/each';
import get from 'lodash/get';
import size from 'lodash/size';
import last from 'lodash/last';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import concat from 'lodash/concat';
import without from 'lodash/without';

import { mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiCheckbox } from '@emobg/motion-ui/v1';

import { DATE_FORMAT, navigationErrorHandler, reformatDateTime } from '@emobg/web-utils';
import { LinkSwitch } from '@/components';

import { duration } from '../../../utils/duration';
import OccurrenceForm from './components/OccurrenceForm/OccurrenceForm';
import DeleteOccurrenceForm from './components/DeleteOccurrenceForm/DeleteOccurrenceForm';
import OccurrenceCellTemplate from './components/OccurrenceCellTemplate/OccurrenceCellTemplate';
import UnsavedChangesModal from '../UnsavedChangesModal/UnsavedChangesModal';
import carsharing from '../../../router/CarsharingRouterMap';
import { CELL_STATUS } from './components/OccurrenceCellTemplate/const';

export default {
  components: {
    LinkSwitch,
    MuiCheckbox,
    OccurrenceForm,
    OccurrenceCellTemplate,
    DeleteOccurrenceForm,
    UnsavedChangesModal,
  },
  data() {
    return {
      isOccurrenceFormOpen: false,
      isDeleteOccurrenceFormOpen: false,
      showUnsavedChangesModal: false,
      currentOccurrence: null,
      checkedCells: [],
      loading: false,
    };
  },
  computed: {
    hasUnsavedChanges() {
      return filter(this.bookingSet.vehicles, vehicle => this.cellsSaveableByVehicle(vehicle.uuid).length).length;
    },
    pendingCells() {
      return filter(this.bookingSet.cells, cell => ([
        CELL_STATUS.pending,
        CELL_STATUS.failed,
      ].includes(cell.status)));
    },
    successCells() {
      return filter(this.bookingSet.cells, cell => !this.isCellAbleToSelect(cell));
    },
    failedCells() {
      return filter(this.bookingSet.cells, { status: CELL_STATUS.failed });
    },
    unselectedCells() {
      return filter(this.bookingSet.cells, cell => !this.checkedCells.includes(cell.uuid) && this.isCellAbleToSelect(cell));
    },
    bookingsNumber() {
      const totalCount = this.checkedCells.length;
      return totalCount >= 0 ? totalCount : 0;
    },
    sortedOccurrences() {
      const { occurrences } = this.bookingSet;
      return occurrences.sort((firstSet, secondSet) => moment(firstSet.start).diff(secondSet.start));
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
      bookingSetCellsSaveStatus: state => state.bookingSetCellsSave.STATUS,
    }),
  },
  created() {
    this.duration = duration;
    this.checkedCells = map(this.pendingCells, 'uuid');
    this.DATE_FORMAT = { ...DATE_FORMAT };
    this.occurrencesWidth = 400;
    this.vehiclesWidth = 120;
    this.occurrencesLimit = 6;
  },
  methods: {
    size,
    get,
    last,
    map,
    toLower,
    redirect() {
      this.$router.push({ name: carsharing.bookingSets.list }).catch(navigationErrorHandler);
    },
    isCellAbleToSelect(cell) {
      return [
        CELL_STATUS.failed,
        CELL_STATUS.skipped,
        CELL_STATUS.pending,
      ].includes(cell.status);
    },
    isCellPendingUnselected(cell) {
      return [
        CELL_STATUS.pending,
        CELL_STATUS.failed,
      ].includes(cell.status) && map(this.unselectedCells, 'uuid').includes(cell.uuid);
    },
    openEditOccurrenceModal(occurrence) {
      this.isOccurrenceFormOpen = true;
      this.currentOccurrence = occurrence;
    },
    openDeleteOccurrenceModal(occurrence) {
      this.isDeleteOccurrenceFormOpen = true;
      this.currentOccurrence = occurrence;
    },
    closeOccurrenceModal(occurrenceUuid) {
      this.isOccurrenceFormOpen = false;
      this.isDeleteOccurrenceFormOpen = false;
      this.currentOccurrence = null;

      if (occurrenceUuid) {
        const newCells = map(filter(this.bookingSet.cells, { occurrenceUuid }), 'uuid');
        this.checkedCells = [...this.checkedCells, ...newCells];
      }
    },
    selectableCellsByOccurrence(occurrenceUuid) {
      return filter(this.bookingSet.cells, cell => {
        const isTargetOccurrence = cell.occurrenceUuid === occurrenceUuid;
        return isTargetOccurrence && this.isCellAbleToSelect(cell);
      }) || [];
    },
    cellByOccurrenceAndVehicle(vehicleUuid, occurrenceUuid) {
      return find(this.bookingSet.cells, { vehicleUuid, occurrenceUuid }) || {};
    },
    isVehicleIncluded(vehicleUuid, occurrenceUuid) {
      return includes(this.checkedCells, this.cellByOccurrenceAndVehicle(vehicleUuid, occurrenceUuid).uuid);
    },
    handleCheckedCellsUpdate(vehicleUuid, occurrenceUuid) {
      const cellUuid = this.cellByOccurrenceAndVehicle(vehicleUuid, occurrenceUuid).uuid;

      this.checkedCells = this.isVehicleIncluded(vehicleUuid, occurrenceUuid)
        ? without(this.checkedCells, cellUuid)
        : concat(this.checkedCells, cellUuid);
    },
    cellsSaveableByVehicle(vehicleUuid) {
      return filter(this.bookingSet.cells, cell => {
        const isPendingUnselected = this.isCellPendingUnselected(cell);
        const isSkippedSelected = cell.status === CELL_STATUS.skipped && this.checkedCells.includes(cell.uuid);
        return cell.vehicleUuid === vehicleUuid && (isSkippedSelected || isPendingUnselected);
      });
    },
    cellsBookeableByVehicle(vehicleUuid) {
      return filter(this.bookingSet.cells, cell => {
        const isPendingUnselected = this.isCellPendingUnselected(cell);
        const cellInChecked = this.checkedCells.includes(cell.uuid);
        return cell.vehicleUuid === vehicleUuid && (isPendingUnselected || cellInChecked);
      });
    },
    dataToSend(vehicleUuid, cellFilterer) {
      const cells = cellFilterer(vehicleUuid);
      const request = { booking_set_cells: [] };
      each(cells, cell => {
        request.booking_set_cells.push({
          booking_set_occurrence_uuid: cell.occurrenceUuid,
          create: this.checkedCells.includes(cell.uuid),
        });
      });

      return request;
    },
    hasBookings(occurrenceUuid) {
      return filter(filter(this.bookingSet.cells, { occurrenceUuid }), { status: 'booked' }).length;
    },
    async saveCells() {
      const saveCellsPromises = [];
      each(this.bookingSet.vehicles, vehicle => {
        const request = this.dataToSend(vehicle.uuid, this.cellsSaveableByVehicle);
        if (get(request, 'booking_set_cells.length')) {
          saveCellsPromises.push(this.patchBookingSetCellsSave({
            bookingSetUuid: this.bookingSet.uuid,
            vehicleUuid: vehicle.uuid,
            request,
          }));
        }
      });

      this.loading = true;
      await Promise.all(saveCellsPromises);
      await this.getBookingSet({ bookingSetUuid: get(this, '$route.params.bookingSetUuid') });
      this.loading = false;
    },
    async createBookings() {
      this.loading = true;
      for (let index = 0; index < get(this, 'bookingSet.vehicles.length', 0); index += 1) {
        const vehicle = get(this.bookingSet.vehicles, index);
        const request = this.dataToSend(vehicle.uuid, this.cellsBookeableByVehicle);

        if (get(request, 'booking_set_cells.length')) {
          // eslint-disable-next-line no-await-in-loop
          await this.patchBookingSetCellsBook({
            bookingSetUuid: this.bookingSet.uuid,
            vehicleUuid: vehicle.uuid,
            request,
          });

          this.updateProcessedCells();
        }
      }
      await this.getBookingSet({ bookingSetUuid: get(this, '$route.params.bookingSetUuid') });
      this.checkedCells = map(this.pendingCells, 'uuid');
      this.loading = false;
    },
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'patchBookingSetCellsSave',
      'patchBookingSetCellsBook',
      'getBookingSet',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.bookingSets.information, ['updateProcessedCells']),
    reformatDateTime,
    updateCheckedCells() {
      this.checkedCells = map(this.pendingCells, 'uuid');
    },
  },
};
</script>
<template>
  <div>
    <ui-card
      v-if="bookingSet"
      class="OccurrencesComponent d-flex flex-column flex-fill"
    >
      <div class="d-flex flex-wrap">
        <div class="col-12 p-3">
          <h3 class="emobg-font-weight-bold">
            Create up to {{ occurrencesLimit }} occurrences
            <ui-tooltip
              tooltip="Select when bookings should happen. You can unselect the occurrences you wish to skip"
              class="d-inline"
            >
              <ui-icon
                :icon="ICONS.infoFull"
                :size="ICONS_SIZES.small"
                class="emobg-color-ink-light emobg-color-ink-hover"
                data-test-id="icon"
              />
            </ui-tooltip>
          </h3>
          <p class="emobg-color-ink-light my-3">
            Step 3 of 3
          </p>

          <section>
            <p class="emobg-background-color-ground-lightest emobg-font-weight-bold p-3">
              Occurrences created ({{ size(bookingSet.occurrences) }})
            </p>
            <div class="my-3">
              <p
                v-if="unselectedCells.length"
                class="row my-md-2"
              >
                <ui-alert
                  :color="COLORS.primary"
                  class="col-lg-6"
                >
                  Skipped bookings ({{ unselectedCells.length }})
                </ui-alert>
              </p>
              <p
                v-if="successCells.length"
                class="row my-md-2"
              >
                <ui-alert class="col-lg-6">
                  Created bookings ({{ successCells.length }})
                </ui-alert>
              </p>
              <p
                v-if="failedCells.length"
                class="row my-md-2"
              >
                <ui-alert
                  :color="COLORS.danger"
                  class="col-lg-6"
                >
                  Failed bookings ({{ failedCells.length }})
                </ui-alert>
              </p>
            </div>
            <p class="text-right my-md-2">
              <ui-button
                :face="FACES.outline"
                :disabled="size(bookingSet.occurrences) === occurrencesLimit"
                @clickbutton="isOccurrenceFormOpen = true"
              >
                Create occurrence
              </ui-button>
            </p>
          </section>

          <div
            class="OpsGrid d-flex
            emobg-border-1 emobg-border-color-ground-light
            "
          >
            <div
              class="with-shadow flex-shrink-0
              emobg-border-right-1 emobg-border-color-ground-light"
              :class="{
                'flex-grow-1': !get(bookingSet, 'occurrences.length')
              }"
              :style="{
                width: vehiclesWidth + 'px',
              }"
            >
              <header
                class="Header__row
                emobg-background-color-ground-lighter emobg-font-line-height-medium
                d-flex align-items-center
                emobg-border-bottom-4 emobg-border-color-ground-light"
              >
                <div
                  :class="[
                    'px-2 py-3 px-md-3 py-md-4 Header__row',
                    {
                      'col-4 col-md-3 col-lg-2 emobg-border-right-1 emobg-border-color-ground-light': !get(bookingSet, 'occurrences.length')
                    }]"
                >
                  <p class="emobg-font-weight-bold">
                    Vehicles ({{ size(bookingSet.vehicles) }})
                  </p>
                </div>
              </header>
              <section class="OpsGrid__content">
                <div
                  v-for="(vehicle, vehicleIndex) in bookingSet.vehicles"
                  :key="`OpsGrid-vehicle-${vehicleIndex}`"
                  :class="[
                    'SelectVehicles__row',
                    'd-flex align-items-start justify-content-center flex-column',
                    'emobg-border-color-ground-light',
                    {
                      'emobg-border-bottom-1' : get(bookingSet, 'occurrences.length')
                    },
                    {
                      'emobg-background-color-ground-lightest': vehicleIndex % 2,
                    },
                    {
                      'emobg-border-right-1': !get(bookingSet, 'occurrences.length')
                    }
                  ]"
                >
                  <div
                    :class="['p-2 p-md-3',
                             {
                               'col-4 col-md-3 col-lg-2 pl-0 emobg-border-right-1 emobg-border-color-ground-light':
                                 !get(bookingSet, 'occurrences.length')
                             }
                    ]"
                  >
                    <LinkSwitch
                      use-router
                      :to="`/fleet/vehicles/${vehicle.vehicleUuid}/details`"
                      class="emobg-link-primary emobg-body-2"
                      target="_blank"
                    >
                      {{ vehicle.licensePlate }}
                    </LinkSwitch>
                    <p>
                      {{ vehicle.brand }} {{ vehicle.model }}
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div
              v-if="get(bookingSet, 'occurrences.length')"
              class="overflow-x-auto overflow-y-hidden
              flex-grow-1 d-flex OccurrencesList"
            >
              <div
                v-for="(occurrence, occurrenceIndex) in sortedOccurrences"
                :key="`OpsGrid-occurrence-${occurrenceIndex}`"
                class="OccurrencesList__column flex-shrink-0"
              >
                <header
                  class="Header__row p-2 p-md-3
            emobg-background-color-ground-lighter emobg-font-line-height-medium
            emobg-border-bottom-4 emobg-border-color-ground-light
            d-flex align-items-center justify-content-between"
                  :class="{'emobg-border-right-1': occurrence !== last(sortedOccurrences)}"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                    :style="{ maxWidth: occurrencesWidth + 'px' }"
                  >
                    <MuiCheckbox
                      v-model="checkedCells"
                      :size="SIZES.small"
                      :values="map(selectableCellsByOccurrence(occurrence.uuid), 'uuid')"
                      class="d-flex flex-column"
                    />
                    <div class="d-flex flex-column px-1">
                      <strong class="emobg-font-weight-bold pb-1">
                        Occurrence
                      </strong>
                      <div class="emobg-font-x-small">
                        <span>
                          {{ reformatDateTime(occurrence.start, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                        </span>
                        to
                        <span>
                          {{ reformatDateTime(occurrence.end, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                        </span>
                      </div>
                      <div class="emobg-font-x-small emobg-color-ink-light pb-1">
                        <span>
                          {{ duration(occurrence.start, occurrence.end) }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <ui-dropdown
                        v-if="!loading && !hasBookings(occurrence.uuid)"
                        :color="GRAYSCALE.groundLighter"
                      >
                        <ui-button
                          slot="trigger"
                          :color="GRAYSCALE.groundLighter"
                          contrast
                          square
                        >
                          <ui-icon
                            :color="GRAYSCALE.inkLight"
                            :icon="ICONS.optionsHorizontalFull"
                          />
                        </ui-button>
                        <ui-dropdown-actions
                          slot="content"
                          :actions.prop="[{
                            label: 'Edit',
                            action: () => openEditOccurrenceModal(occurrence),
                          }, {
                            label: 'Delete',
                            action: () => openDeleteOccurrenceModal(occurrence),
                          }]"
                          data-test-id="list"
                        />
                      </ui-dropdown>
                    </div>
                  </div>
                </header>
                <section
                  class="OpsGrid__content"
                  :class="{'emobg-border-right-1 emobg-border-color-ground-light': occurrence !== last(sortedOccurrences)}"
                >
                  <div
                    v-for="(vehicle, vehicleIndex) in bookingSet.vehicles"
                    :key="`OpsGrid-vehicle-${vehicleIndex}`"
                    class="SelectVehicles__row d-flex align-items-center p-2 px-md-3 py-md-4
                          emobg-border-bottom-1 emobg-border-color-ground-light"
                    :class="{'emobg-background-color-ground-lightest': vehicleIndex % 2}"
                  >
                    <ui-checkbox
                      :checked="isVehicleIncluded(vehicle.uuid, occurrence.uuid)"
                      :disabled="!isCellAbleToSelect(cellByOccurrenceAndVehicle(vehicle.uuid, occurrence.uuid))"
                      @changevalue="() => handleCheckedCellsUpdate(vehicle.uuid, occurrence.uuid)"
                    />
                    <OccurrenceCellTemplate
                      :cell="cellByOccurrenceAndVehicle(vehicle.uuid, occurrence.uuid)"
                      :type="toLower(bookingSet.type)"
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="emobg-border-top-1 emobg-border-color-ground-light p-3">
        <div class="d-flex align-items-center justify-content-end">
          <ui-button
            :face="FACES.outline"
            :color="GRAYSCALE.inkLight"
            :disabled="loading"
            class="mr-2"
            @clickbutton="hasUnsavedChanges ? showUnsavedChangesModal = true : redirect()"
          >
            Close
          </ui-button>

          <ui-button
            :disabled="!bookingsNumber"
            :loading="loading"
            @clickbutton="createBookings"
          >
            Create bookings ({{ bookingsNumber }})
          </ui-button>
        </div>
      </div>
    </ui-card>

    <OccurrenceForm
      v-if="isOccurrenceFormOpen"
      :close="closeOccurrenceModal"
      :occurrence="currentOccurrence"
      @closeModal="closeOccurrenceModal"
      @modal-closed="closeOccurrenceModal"
    />
    <DeleteOccurrenceForm
      v-if="isDeleteOccurrenceFormOpen"
      :close="closeOccurrenceModal"
      :occurrence="currentOccurrence"
      @closeModal="closeOccurrenceModal"
      @modal-closed="closeOccurrenceModal"
      @deleteOccurence="updateCheckedCells"
    />
    <UnsavedChangesModal
      v-if="showUnsavedChangesModal"
      :status="bookingSetCellsSaveStatus"
      :loading="loading"
      title="Save changes before closing?"
      :close="() => showUnsavedChangesModal = false"
      :discard="redirect"
      :save="saveCells"
      @closeModal="() => showUnsavedChangesModal = false"
      @modal-closed="() => showUnsavedChangesModal = false"
    />
  </div>
</template>
<style lang="scss">
.OccurrencesComponent {
  .Ui-Card__body {
    border-top: 0 !important;
  }

  .SelectVehicles {
    &__row {
      height: 5.25em;
    }
  }

  .Header__row {
    height: 5em;
  }

  .with-shadow {
    position: relative;
    box-shadow: 4px 0 4px 0 rgba(200, 200, 200, 0.3);
  }

  .OccurrencesList {
    &__column {
      &:last-child {
        flex-grow: 1;
      }
    }
  }
}
</style>
