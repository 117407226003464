<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import size from 'lodash/size';
import pick from 'lodash/pick';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import each from 'lodash/each';
import values from 'lodash/values';
import kebabCase from 'lodash/kebabCase';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  Validate,
} from '@emobg/motion-ui/v1';

import { navigationErrorHandler, sentenceCase } from '@emobg/web-utils';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { BOOKING_TYPES } from '../../../const/bookingTypes';
import CostAllocationsComponent from './components/CostAllocations/CostAllocationsComponent';
import { CELL_STATUS } from '../Occurrences/components/OccurrenceCellTemplate/const';
import { BOOKING_SET_STATUS } from '../../const/bookingSets.const';

export default {
  directives: {
    Validate,
  },
  components: {
    CostAllocationsComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
  },
  data() {
    return {
      isInitialized: false,
      isCostAllocationsValid: true,
      inputs: {
        name: '',
        type: BOOKING_TYPES.intervention,
        userUuid: null,
        userProfileUuid: null,
        insuranceUuid: null,
        interventionType: null,
        description: null,
        companyCostAllocations: [],
      },
    };
  },
  computed: {
    isEditing() {
      return get(this, 'bookingSet.status') === BOOKING_SET_STATUS.created;
    },
    isBookingCreated() {
      return !!find(get(this, 'bookingSet.cells'), cell => [
        CELL_STATUS.booked,
        CELL_STATUS.modified,
        CELL_STATUS.cancelled,
        CELL_STATUS.finished,
      ].includes(cell.status));
    },
    areRequiredFieldsFilled() {
      const isCostAllocationsSkippable = size(this.currentProfile.costAllocations)
        ? this.isCostAllocationsValid
        : true;

      switch (this.inputs.type) {
        case BOOKING_TYPES.intervention:
          return this.inputs.name && this.inputs.userProfileUuid && this.inputs.interventionType && this.inputs.description;
        case BOOKING_TYPES.unavailability:
          return this.inputs.name && this.inputs.description;
        default:
          return this.inputs.name && this.inputs.userProfileUuid && this.inputs.insuranceUuid && isCostAllocationsSkippable;
      }
    },
    isCarsharing() {
      return [
        BOOKING_TYPES.carsharing,
        BOOKING_TYPES.longDistance,
      ].includes(this.inputs.type);
    },
    isInterventionOrUnavailability() {
      return [
        BOOKING_TYPES.intervention,
        BOOKING_TYPES.unavailability,
      ].includes(this.inputs.type);
    },
    insurances() {
      return get(this.currentProfile, 'insurances', []);
    },
    currentProfile() {
      return find(this.userProfiles, { uuid: this.inputs.userProfileUuid }) || {};
    },
    dataToSend() {
      let request = null;

      switch (this.inputs.type) {
        case BOOKING_TYPES.unavailability:
          request = pick(this.inputs, ['name', 'type', 'description']);
          request.companyCostAllocations = [];
          break;
        case BOOKING_TYPES.intervention:
          request = pick(this.inputs, ['name', 'type', 'userProfileUuid', 'interventionType', 'description']);
          request.companyCostAllocations = [];
          break;
        default:
          request = pick(this.inputs, ['name', 'type', 'userProfileUuid', 'insuranceUuid', 'companyCostAllocations', 'description']);
          break;
      }

      return {
        bookingSetUuid: this.isEditing ? get(this, 'bookingSet.uuid', null) : null,
        request,
      };
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userLocale: state => get(state, 'user.data.locale_code', 'en_GB'),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      userProfiles: state => state.userProfiles.data.profiles,
      isUserProfilesLoading: state => state.userProfiles.STATUS.LOADING,
      bookingSetStatus: state => state.bookingSet.STATUS,
      bookingSet: state => state.bookingSet.data,
    }),
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.sentenceCase = sentenceCase;
    this.BOOKING_TYPES = BOOKING_TYPES;
    const isOnEditView = !isEmpty(get(this, '$route.params.bookingSetUuid'));
    if (isOnEditView) {
      await this.getBookingSet({ bookingSetUuid: get(this, '$route.params.bookingSetUuid') });
      const bookingSetType = get(this, 'bookingSet.type');
      if (bookingSetType !== BOOKING_TYPES.unavailability) {
        await this.getBookingSetUser({ userUuid: get(this, 'bookingSet.userUuid'), params: { bookingSetType } });
      }

      this.inputs.name = get(this, 'bookingSet.name', null);
      this.inputs.type = get(this, 'bookingSet.type', BOOKING_TYPES.carsharing);
      this.inputs.userUuid = get(this, 'bookingSet.userUuid', null);
      this.inputs.userProfileUuid = get(this, 'bookingSet.userProfileUuid', null);
      this.inputs.insuranceUuid = get(this, 'bookingSet.insuranceUuid', null);
      this.inputs.interventionType = get(this, 'bookingSet.interventionType', null);
      this.inputs.description = get(this, 'bookingSet.description', null);
      this.inputs.companyCostAllocations = this.transformCostAllocations(get(this, 'bookingSet.companyCostAllocations', []));
    } else {
      this.resetStore();
    }

    this.isInitialized = true;
  },
  methods: {
    values,
    kebabCase,
    isEmpty,
    map,
    transformCostAllocations(costAllocations) {
      return map(costAllocations, ({ uuid, childUuid, customValue }) => ({
        parentUuid: uuid,
        value: childUuid || customValue,
        type: childUuid ? 'select' : 'text',
      }));
    },
    interventionTitle(result) {
      const value = result.name_translations[`${this.userLocale}`];
      return isEmpty(value) ? result.name_translations.en_GB : value;
    },
    onUserChange(value) {
      if (!isEqual(this.inputs.userUuid, value)) {
        this.inputs.userUuid = value;
        this.inputs.companyCostAllocations = [];
        this.updateUserInfo();
      }
    },
    onChangeProfile(value, forceUpdate = false) {
      if (value !== this.inputs.userProfileUuid || forceUpdate) {
        this.inputs.userProfileUuid = value;
        this.inputs.insuranceUuid = get(this.insurances, '[0].uuid', null);
      }
    },
    resetSelectorsField(reset = null) {
      const reseteableFields = reset ? [reset] : ['userProfileUuid', 'insuranceUuid'];

      each(reseteableFields, item => {
        this.inputs[item] = null;
      });
    },
    redirect(moveBack) {
      if (this.isEditing || moveBack) {
        this.$router.back();
      } else {
        this.$router.push(`/carsharing/booking-sets/${get(this, 'bookingSet.uuid')}/select-vehicles`).catch(navigationErrorHandler);
      }
    },
    async doRequest() {
      const request = this.isEditing ? this.putBookingSet : this.postBookingSet;
      await request(this.dataToSend);

      if (!this.bookingSetStatus.ERROR) {
        this.redirect(false);
      }
    },
    async updateUserInfo() {
      if (this.inputs.userUuid) {
        await this.getBookingSetUser({ userUuid: this.inputs.userUuid, params: { bookingSetType: this.inputs.type } });
        this.inputs.userProfileUuid = get(this.userProfiles, '[0].uuid', null);
        this.onChangeProfile(this.inputs.userProfileUuid, true);
      } else {
        this.resetSelectorsField();
      }
    },
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'getBookingSetUser',
      'getBookingSet',
      'postBookingSet',
      'putBookingSet',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'resetStore',
    ]),
  },
};
</script>

<template>
  <div
    class="GeneralInformationView"
    data-test-id="general_information-view"
  >
    <h2 class="mb-2">
      {{ isEditing ? 'Edit booking set' : 'Create booking set' }}
    </h2>
    <ui-card
      class="d-flex flex-column flex-fill"
      data-test-id="card"
    >
      <div class="d-flex flex-wrap">
        <div class="col-12 p-3">
          <h3 class="emobg-font-weight-bold">
            Add details
          </h3>
          <p class="emobg-color-ink-light my-3">
            Step 1 of 3
          </p>
          <hr class="emobg-border-top-1 emobg-border-color-ground-light my-3">
          <div class="row">
            <div class="col-12 mb-3">
              <MuiInputText
                v-model="inputs.name"
                v-validate="{
                  isRequired: true,
                }"
                label="Name*"
                placeholder="Name this booking set"
                name="name"
                data-test-id="booking_set_name-input"
              />
            </div>

            <div class="col-12 d-flex flex-column align-items-start mb-3">
              <span class="d-block emobg-font-small emobg-font-weight-semibold emobg-color-ink pl-1">Booking type*</span>
              <ui-radio
                v-for="type in values(BOOKING_TYPES)"
                :key="type"
                :value="inputs.type"
                :caption="sentenceCase(type)"
                :option="type"
                :disabled="isBookingCreated"
                name="type"
                :data-test-id="`booking_${kebabCase(type)}-update-button`"
                @changevalue="() => {
                  inputs.type = type;
                  updateUserInfo();
                }"
              />
            </div>

            <div
              v-if="inputs.type !== BOOKING_TYPES.unavailability"
              class="col-12 mb-3"
            >
              <span class="d-block emobg-font-small emobg-font-weight-semibold emobg-color-ink pl-1 mb-1">User*</span>
              <MuiAlgoliaSelect
                v-if="isInitialized"
                :disabled="isBookingCreated"
                :filters="getOperatorFilter({ index: ALGOLIA_INDEXES.users })"
                :index="ALGOLIA_INDEXES.users"
                :results-per-page="50"
                :title="result => `${result.first_name} ${result.last_name} - ${result.email ? result.email : '[No email]'}`"
                :model="inputs.userUuid"
                class="w-100 mb-0 pb-2"
                name="user"
                path-value="uuid"
                placeholder="Select"
                data-test-id="user-select"
                @change="onUserChange"
              />
            </div>

            <div
              v-if="inputs.type !== BOOKING_TYPES.unavailability"
              class="col-12 mb-3"
            >
              <ui-skeleton
                v-if="isUserProfilesLoading"
                class="mb-2"
              />
              <MuiSelect
                v-else
                v-validate="{
                  isRequired: true,
                }"
                :model-value="inputs.userProfileUuid"
                :disabled="isEmpty(inputs.userUuid) || isBookingCreated"
                :options="map(userProfiles, profile => ({value: profile.uuid, label: sentenceCase(profile.type)}))"
                placeholder="Select"
                label="Profile*"
                name="profile"
                class="w-100 mb-2"
                data-test-id="profile-select"
                @change="onChangeProfile"
              />
            </div>

            <div
              v-show="isCarsharing"
              class="col-12 mb-3"
            >
              <ui-skeleton
                v-if="isUserProfilesLoading"
                class="mb-2"
              />
              <MuiSelect
                v-else
                v-model="inputs.insuranceUuid"
                v-validate="{
                  isRequired: true,
                }"
                :disabled="isEmpty(inputs.userProfileUuid) || isBookingCreated"
                :options="map(insurances, ({uuid, name }) => ({ value: uuid, label: name }))"
                placeholder="Select"
                label="Insurance*"
                class="w-100 mb-2"
                name="insurance"
                data-test-id="insurance-select"
              />
            </div>

            <div
              v-show="inputs.type === BOOKING_TYPES.intervention"
              class="col-12 mb-3"
            >
              <MuiAlgoliaSelect
                v-if="isInitialized"
                v-model="inputs.interventionType"
                :disabled="!inputs.userProfileUuid || isBookingCreated"
                :index="ALGOLIA_INDEXES.carsharingTags"
                :results-per-page="50"
                :title="interventionTitle"
                class="w-100 m-0 pb-2"
                filters="group:interventions"
                label="Intervention type*"
                name="intervention"
                path-value="internal_name"
                placeholder="Select"
                data-test-id="intervention-select"
              />
            </div>

            <div
              v-if="currentProfile.costAllocations && currentProfile.costAllocations.length"
              class="col-12 mb-3"
            >
              <CostAllocationsComponent
                v-model="inputs.companyCostAllocations"
                :disabled="isBookingCreated"
                :company-cost-allocations="currentProfile.costAllocations"
                data-test-id="cost_allocations"
                @isValid="value => isCostAllocationsValid = value"
              />
            </div>
            <div class="col-12 mb-3">
              <ui-text-area
                :value="inputs.description"
                :label="`Description${isInterventionOrUnavailability ? '*' : ''}`"
                name="description"
                rows="8"
                data-test-id="description-textarea"
                @changevalue="event => inputs.description = event.detail"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="emobg-border-top-1 emobg-border-color-ground-light p-3">
        <div class="d-flex align-items-center justify-content-end">
          <ui-button
            :color="GRAYSCALE.ink"
            :face="FACES.text"
            class="mr-2"
            data-test-id="redirect-button"
            @clickbutton="redirect(true)"
          >
            {{ $t('Common.Actions.cancel') }}
          </ui-button>
          <ui-button
            :disabled="!areRequiredFieldsFilled"
            :loading="bookingSetStatus.LOADING"
            data-test-id="request-button"
            @clickbutton="doRequest"
          >
            {{ isEditing ? 'Save' : 'Next' }}
          </ui-button>
        </div>
      </div>
    </ui-card>
  </div>
</template>
