<script>
export default {
  props: {
    vehicles: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    vehiclesLength() {
      return this.vehicles.length ? `(${this.vehicles.length})` : '';
    },
  },
};
</script>
<template>
  <div
    class="BookingSetList__VehiclesTemplate mt-n2"
    data-test-id="booking_sets-vehicles_template"
  >
    <div class="emobg-font-weight-bold emobg-color-ink mb-1 mb-sm-2">
      Vehicles {{ vehiclesLength }}
    </div>
    <template v-if="vehicles.length">
      <div
        v-for="vehicle in vehicles"
        :key="vehicle.vehicle_uuid"
        class="emobg-font-small pb-1"
      >
        <RouterLink
          :to="{ path: `/fleet/vehicles/${vehicle.vehicle_uuid}/details` }"
          class="emobg-link-primary emobg-body-2"
          target="_blank"
          :data-test-id="`${vehicle.vehicle_uuid}-link`"
        >
          {{ vehicle.license_plate }}
        </RouterLink>
        {{ vehicle.model }}
      </div>
    </template>
    <div v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
