<script>
import join from 'lodash/join';
import map from 'lodash/map';
export default {
  props: {
    cities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    citiesText() {
      return join(map(this.cities, 'name'), ', ');
    },
  },
};
</script>
<template>
  <div
    class="BookingSetList__CitiesTemplate"
    data-test-id="booking_sets-cities_template"
  >
    <div
      v-if="cities.length"
      :title="citiesText"
      class="d-flex"
      data-test-id="cities"
    >
      <div
        class="text-ellipsis"
        data-test-id="text"
      >
        {{ citiesText }}
      </div>
      <div
        v-show="cities.length"
        class="pl-1"
        data-test-id="count"
      >
        ({{ cities.length }})
      </div>
    </div>
    <div v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
