var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiCard",
    {
      staticClass: "d-flex flex-column flex-fill",
      staticStyle: { "border-top": "0" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-wrap" },
        [
          _c("div", { staticClass: "col-12 p-3" }, [
            _c(
              "h3",
              { staticClass: "emobg-font-weight-bold" },
              [
                _vm._v(
                  " Select up to " +
                    _vm._s(_vm.maxVehiclesSelected) +
                    " vehicles "
                ),
                _c(
                  "ui-tooltip",
                  {
                    staticClass: "d-inline",
                    attrs: {
                      tooltip:
                        "Please ensure you select vehicles which are applicable to the booking type and the selected user",
                    },
                  },
                  [
                    _c("ui-icon", {
                      staticClass:
                        "emobg-color-ink-light emobg-color-ink-hover",
                      attrs: {
                        icon: _vm.ICONS.infoFull,
                        size: _vm.ICONS_SIZES.small,
                        "data-test-id": "icon",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("p", { staticClass: "emobg-color-ink-light my-2" }, [
              _vm._v(" Step 2 of 3 "),
            ]),
          ]),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "p-3 emobg-background-color-ground-lightest" },
              [
                _c("h4", { staticClass: "emobg-font-weight-bold pb-2" }, [
                  _vm._v(" Selected vehicles "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.vehicles.length,
                          expression: "vehicles.length",
                        },
                      ],
                    },
                    [_vm._v(" (" + _vm._s(_vm.vehicles.length) + ") ")]
                  ),
                ]),
                _vm.vehicles.length
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-wrap" },
                      _vm._l(_vm.vehicles, function (vehicle) {
                        return _c("VehicleSummaryCard", {
                          key: vehicle.vehicleUuid,
                          attrs: {
                            uuid: vehicle.vehicleUuid,
                            "license-plate": vehicle.licensePlate,
                            model: `${vehicle.brand} ${vehicle.model}`,
                            location: vehicle.locationName,
                            disabled: _vm.hasCellsBooked(vehicle.vehicleUuid),
                            "on-remove": _vm.removeVehicleFromSelected,
                          },
                        })
                      }),
                      1
                    )
                  : _c("div", [
                      _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                    ]),
              ]
            ),
          ]),
          _c("MuiCollapse", { attrs: { open: _vm.isFeedbackVisible } }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "ui-alert",
                  {
                    staticClass: "col-lg-6",
                    attrs: { color: _vm.COLORS.primary },
                  },
                  [
                    _vm._v(
                      " You've selected " +
                        _vm._s(_vm.size(_vm.vehicles)) +
                        " vehicles. This is the maximum. "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "MuiAlgoliaList",
            _vm._b(
              {
                staticClass: "w-100 border-color-white",
                attrs: { "no-cache": "" },
              },
              "MuiAlgoliaList",
              {
                ..._vm.vehiclesListConsts,
                ..._vm.vehiclesListProps,
              },
              false
            )
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "emobg-border-top-1 emobg-border-color-ground-light p-3",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-end" },
            [
              _c(
                "ui-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: _vm.GRAYSCALE.inkLight,
                    face: _vm.FACES.text,
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.hasUnsavedChanges
                        ? (_vm.showUnsavedChangesModal = true)
                        : _vm.redirect()
                    },
                  },
                },
                [_vm._v(" Exit ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: {
                    disabled: !_vm.vehicles.length,
                    loading: _vm.bookingSetVehiclesStatus.LOADING,
                  },
                  on: { clickbutton: _vm.syncVehicles },
                },
                [_vm._v(" " + _vm._s(_vm.isEditing ? "Save" : "Next") + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.showUnsavedChangesModal
        ? _c("UnsavedChangesModal", {
            attrs: {
              status: _vm.bookingSetVehiclesStatus,
              title: "Save changes before exiting?",
              close: () => (_vm.showUnsavedChangesModal = false),
              discard: _vm.redirect,
              save: _vm.request,
            },
            on: {
              closeModal: () => (_vm.showUnsavedChangesModal = false),
              "modal-closed": () => (_vm.showUnsavedChangesModal = false),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }