<script>
import { isFalsyString } from '@emobg/web-utils';

export default {
  name: 'VehicleSummaryCard',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      required: true,
    },
    licensePlate: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      default: '',
    },
    onRemove: {
      type: Function,
      default: () => {},
    },
  },
  methods: { isFalsyString },
};
</script>
<template>
  <div
    class="VehicleSummaryCard d-flex justify-content-between h-auto
    emobg-background-color-white emobg-border-color-ground emobg-border-1 m-1 emobg-border-radius-small"
    style="width: 235px;"
  >
    <div class="d-flex flex-grow-1 flex-column p-3">
      <span class="emobg-font-weight-bold pb-1">
        {{ licensePlate }}
      </span>
      <span class="pb-1">
        {{ model }}
      </span>
      <div
        :data-test-id="`vehicle-location-${uuid}`"
        class="emobg-color-ink-light emobg-font-small"
      >
        {{ isFalsyString(location) ? FALLBACK_MESSAGE.dash : location }}
      </div>
    </div>
    <div class="d-flex flex-grow-0 p-1">
      <ui-button
        data-test-id="vehicle-close-button"
        :face="FACES.text"
        :color="GRAYSCALE.inkLight"
        :disabled="disabled"
        square
        @clickbutton="onRemove(uuid)"
      >
        <ui-icon
          :icon="ICONS.removeFilled"
          :size="ICONS_SIZES.small"
        />
      </ui-button>
    </div>
  </div>
</template>
