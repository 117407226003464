<script>
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import size from 'lodash/size';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { mapActions, mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { MuiCard, MuiCollapse } from '@emobg/motion-ui/v1';
import { navigationErrorHandler } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import VehicleSummaryCard from '@/components/VehicleSummaryCard/VehicleSummaryCard';
import carsharing from '../../../router/CarsharingRouterMap';
import { CELL_STATUS } from '../Occurrences/components/OccurrenceCellTemplate/const';

import {
  algoliaListConstants,
  algoliaListScoped,
  maxVehiclesSelected,
} from './const/vehiclesList';
import UnsavedChangesModal from '../UnsavedChangesModal/UnsavedChangesModal';

export default {
  components: {
    MuiAlgoliaList,
    MuiCard,
    MuiCollapse,
    UnsavedChangesModal,
    VehicleSummaryCard,
  },
  props: {
    vehicles: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: [Boolean, Number],
      default: false,
    },
  },
  data() {
    return {
      showUnsavedChangesModal: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookingSets.information, {
      bookingSet: state => state.bookingSet.data,
      bookingSetVehiclesStatus: state => state.bookingSetVehicle.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorName: state => state.operators.active.name,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    hasUnsavedChanges() {
      const vehiclesFromStore = get(this, 'bookingSet.vehicles', []);
      const vehiclesWithoutUuid = map(vehiclesFromStore, vehicle => omit(vehicle, ['uuid']));

      return !isEqual(this.vehicles, vehiclesWithoutUuid) && this.vehicles.length;
    },
    vehiclesListProps() {
      const operatorFilters = isFunction(this.getOperatorFilter) && this.getOperatorFilter({ index: 'vehicles' });
      return {
        ...algoliaListScoped(this),
        actionsMenuCounter: size(this.vehicles),
        filters: `${operatorFilters} AND non_connected:0`,
      };
    },
    isFeedbackVisible() {
      return size(this.vehicles) > maxVehiclesSelected - 1;
    },
  },
  created() {
    this.maxVehiclesSelected = maxVehiclesSelected;
    this.vehiclesListConsts = algoliaListConstants;
  },
  methods: {
    size,
    async request() {
      await this.putBookingSetVehicleSync({
        bookingSetUuid: this.bookingSet.uuid,
        request: {
          vehicle_uuids: map(this.vehicles, vehicle => vehicle.vehicleUuid),
        },
      });
    },
    async syncVehicles() {
      await this.request();

      if (!this.bookingSetVehiclesStatus.ERROR) {
        if (this.isEditing) {
          this.$router.back();
        } else {
          this.$router.push({
            name: carsharing.bookingSets.occurrences,
            params: {
              bookingSetUuid: get(this, 'bookingSet.uuid'),
            },
          }).catch(navigationErrorHandler);
        }
      }
    },
    redirect() {
      if (this.isEditing) {
        this.$router.back();
      } else {
        this.$router.push({ name: carsharing.bookingSets.list }).catch(navigationErrorHandler);
      }
    },
    removeVehicleFromSelected(vehicleUuid) {
      if (!this.hasCellsBooked(vehicleUuid)) {
        this.$emit('remove-vehicle', vehicleUuid);
      }
    },
    hasCellsBooked(vehicleUuid) {
      const vehicle = find(this.bookingSet.vehicles, { vehicleUuid });
      return filter(this.bookingSet.cells, cell => {
        const belongsToVehicle = cell.vehicleUuid === get(vehicle, 'uuid');
        const isBooked = [
          CELL_STATUS.booked,
          CELL_STATUS.modified,
          CELL_STATUS.cancelled,
          CELL_STATUS.finished,
        ].includes(cell.status);

        return belongsToVehicle && isBooked;
      }).length > 0;
    },
    ...mapActions(DOMAINS_MODEL.carsharing.bookingSets.information, [
      'putBookingSetVehicleSync',
    ]),
  },
};
</script>
<template>
  <MuiCard
    class="d-flex flex-column flex-fill"
    style="border-top: 0;"
  >
    <div class="d-flex flex-wrap">
      <div class="col-12 p-3">
        <h3 class="emobg-font-weight-bold">
          Select up to {{ maxVehiclesSelected }} vehicles
          <ui-tooltip
            tooltip="Please ensure you select vehicles which are applicable to the booking type and the selected user"
            class="d-inline"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="ICONS_SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
              data-test-id="icon"
            />
          </ui-tooltip>
        </h3>
        <p class="emobg-color-ink-light my-2">
          Step 2 of 3
        </p>
      </div>

      <div class="col-12">
        <div class="p-3 emobg-background-color-ground-lightest">
          <h4 class="emobg-font-weight-bold pb-2">
            Selected vehicles <span v-show="vehicles.length"> ({{ vehicles.length }}) </span>
          </h4>
          <div
            v-if="vehicles.length"
            class="d-flex flex-wrap"
          >
            <VehicleSummaryCard
              v-for="vehicle in vehicles"
              :key="vehicle.vehicleUuid"
              :uuid="vehicle.vehicleUuid"
              :license-plate="vehicle.licensePlate"
              :model="`${vehicle.brand} ${vehicle.model}`"
              :location="vehicle.locationName"
              :disabled="hasCellsBooked(vehicle.vehicleUuid)"
              :on-remove="removeVehicleFromSelected"
            />
          </div>
          <div v-else>
            {{ FALLBACK_MESSAGE.dash }}
          </div>
        </div>
      </div>

      <MuiCollapse :open="isFeedbackVisible">
        <div class="row">
          <ui-alert
            :color="COLORS.primary"
            class="col-lg-6"
          >
            You've selected {{ size(vehicles) }} vehicles. This is the maximum.
          </ui-alert>
        </div>
      </MuiCollapse>

      <MuiAlgoliaList
        v-bind="{
          ...vehiclesListConsts,
          ...vehiclesListProps,
        }"
        no-cache
        class="w-100 border-color-white"
      />
    </div>
    <div class="emobg-border-top-1 emobg-border-color-ground-light p-3">
      <div class="d-flex align-items-center justify-content-end">
        <ui-button
          :color="GRAYSCALE.inkLight"
          :face="FACES.text"
          class="mr-2"
          @clickbutton="hasUnsavedChanges ? showUnsavedChangesModal = true : redirect()"
        >
          Exit
        </ui-button>

        <ui-button
          :disabled="!vehicles.length"
          :loading="bookingSetVehiclesStatus.LOADING"
          @clickbutton="syncVehicles"
        >
          {{ isEditing ? 'Save' : 'Next' }}
        </ui-button>
      </div>
    </div>

    <UnsavedChangesModal
      v-if="showUnsavedChangesModal"
      :status="bookingSetVehiclesStatus"
      title="Save changes before exiting?"
      :close="() => showUnsavedChangesModal = false"
      :discard="redirect"
      :save="request"
      @closeModal="() => showUnsavedChangesModal = false"
      @modal-closed="() => showUnsavedChangesModal = false"
    />
  </MuiCard>
</template>

<style lang="scss">
  .MuiCheckbox__wrapper__checkbox__dot {
    width: 16px !important;
  }
</style>
