import { sentenceCase } from '@emobg/web-utils';

export { contentCells } from './contentCells';

export const bookingSetsListFacets = timezone => [
  {
    type: 'DateIntervalsRange',
    props: {
      title: 'Dates',
      dateStart: 'start_date_ts',
      dateEnd: 'end_date_ts',
      labels: {
        from: 'From',
        to: 'To',
        setToday: 'Set to today',
        none: 'None',
        rangeOverlap: 'Active Booking sets',
        dateEnd: 'Booking set end date',
        dateStart: 'Booking set start date',
        infoNone: 'No date filters applied',
        infoRangeOverlap: 'Booking sets being active (in progress) in selected period',
        infoDateStart: 'Booking sets starting in selected period',
        infoDateEnd: 'Booking sets ending in selected period',
      },
      timezone,
      from: null,
      to: null,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Booking type',
      attributeName: 'type',
      transformValue: sentenceCase,
      toggled: false,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Booking set status',
      attributeName: 'status',
      transformValue: sentenceCase,
      toggled: false,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'cities.name',
      toggled: false,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Location',
      attributeName: 'locations.name',
      toggled: false,
      showMore: true,
      limit: 5,
    },
  },
];
